function formatTranscriptData(resultsResponse, newFormattedData){
    let dataTranscript = []
    for (const sessionID in resultsResponse){
      if (resultsResponse.hasOwnProperty(sessionID) && sessionID !== "testInProgress"){
        newFormattedData[sessionID] = {analysis: "", transcript: ""}

        dataTranscript = []
        for (const index in resultsResponse[sessionID]["transcript"]){
          // dataTranscriptString += resultsResponse[sessionID]["transcript"][index] + "\n\n"
          dataTranscript.push(resultsResponse[sessionID]["transcript"][index])
        }
        newFormattedData[sessionID]["transcript"] = [...dataTranscript]
      }
    }
  }

function formatTranscriptDatav1(resultsResponse, newFormattedData){
  let dataTranscriptString = ""
  for (const sessionID in resultsResponse){
    if (resultsResponse.hasOwnProperty(sessionID) && sessionID !== "testInProgress"){
      newFormattedData[sessionID] = {analysis: "", transcript: ""}

      dataTranscriptString = ""
      for (const index in resultsResponse[sessionID]["transcript"]){
        dataTranscriptString += resultsResponse[sessionID]["transcript"][index] + "\n\n"
      }
      newFormattedData[sessionID]["transcript"] = dataTranscriptString
    }
  }
}

  function formatAnalysisData(resultsResponse, newFormattedData, testID){
    newFormattedData["status"] = {analysis: ``, transcript: ""}
    // let statusString = `TEST ID: ${testID}\nTEST IN PROGRESS: ${resultsResponse["testInProgress"]}\nTOKENS USED:\n${JSON.stringify(resultsResponse["usage"])}\n\n\n\n`
    let sessionSummaries = [];
    for (const sessionID in resultsResponse) {
      if (resultsResponse.hasOwnProperty(sessionID) && sessionID !== "testInProgress" && sessionID !== "usage") {
        let sessionSummary = {};
        let session_reporting = resultsResponse[sessionID]["reporting"] || resultsResponse[sessionID]["analysis"];

        sessionSummary["session_id"] = sessionID;
        sessionSummary["session_task"] = resultsResponse[sessionID]["task"];
        if (session_reporting.hasOwnProperty("status")) {
          sessionSummary["session_status"] = session_reporting["status"];
        }
        if (session_reporting.hasOwnProperty("goal_accomplished") && session_reporting.hasOwnProperty("expected_result")){
          sessionSummary["goal_accomplished"] = session_reporting["goal_accomplished"];
          sessionSummary["expected_result"] = session_reporting["expected_result"];
        }
        if (session_reporting.hasOwnProperty("outcome_changed")){
          sessionSummary["outcome_changed"] = session_reporting["outcome_changed"];
        }
        newFormattedData[sessionID]["analysis"] = {
          task: resultsResponse[sessionID]["task"],
          status: session_reporting["status"],
          goal_accomplished: session_reporting["goal_accomplished"],
          expected_result: session_reporting["expected_result"],
          deviation: session_reporting["deviations"] || session_reporting["deviation"],
          outcome_changed: session_reporting["outcome_changed"],
          explanation: session_reporting["explanation"],
        };

        sessionSummaries.push(sessionSummary);
      }
    }
    newFormattedData["status"]["analysis"] = {
      testID: testID,
      testInProgress: resultsResponse["testInProgress"],
      tokenUsed: resultsResponse["usage"],
      sessions: [...sessionSummaries],
      totalTestRun: sessionSummaries.length,
      totalGoalAccomplished: sessionSummaries.reduce( (count, session) =>  session.goal_accomplished === "true" ? count + 1 : count, 0 ),
      totalExpectedResultReached: sessionSummaries.reduce( (count, session) => session.expected_result === "true" ? count + 1 : count, 0 ),
    }
  }

  function formatAnalysisDatav1(resultsResponse, newFormattedData, testID){
    newFormattedData["status"] = {analysis: ``, transcript: ""}
    let statusString = `TEST ID: ${testID}\nTEST IN PROGRESS: ${resultsResponse["testInProgress"]}\nTOKENS USED:\n${JSON.stringify(resultsResponse["usage"])}\n\n\n\n`
    for (const sessionID in resultsResponse){
      if (resultsResponse.hasOwnProperty(sessionID) && sessionID !== "testInProgress" && sessionID !== "usage"){
        let dataAnalysisString = `TEST ID: ${testID}\nTEST IN PROGRESS: ${resultsResponse["testInProgress"]}\n\n\n\nSESSION ID: ${sessionID}\nSESSION TASK: ${resultsResponse[sessionID]["task"]}\n`
        let session_reporting = resultsResponse[sessionID]["reporting"]
        statusString += `SESSION ID: ${sessionID}\nSESSION TASK: ${resultsResponse[sessionID]["task"]}\n`
        if (session_reporting.hasOwnProperty("status")){
          dataAnalysisString += `SESSION STATUS: ${resultsResponse[sessionID]["reporting"]["status"]}\n\n\n\n`
          statusString += `SESSION STATUS: ${resultsResponse[sessionID]["reporting"]["status"]}\n\n\n\n`
        }
        if (session_reporting.hasOwnProperty("goal_accomplished") && session_reporting.hasOwnProperty("expected_result") && session_reporting.hasOwnProperty("explanation")){
          dataAnalysisString += `LLM ANALYSIS:\n\n\nWAS THE SESSION GOAL ACCOMPLISHED?\n${(session_reporting["goal_accomplished"] === "true") ? "YES" : "NO"}\n\n`
          dataAnalysisString += `DID THE RESULT MATCH THE EXPECTED RESULT?\n${(session_reporting["expected_result"] === "true") ? "YES" : "NO"}\n\n`
          dataAnalysisString += `LLM EXPLANATION:\n${session_reporting["explanation"]}\n\n`
        }
        if (session_reporting.hasOwnProperty("deviations") && session_reporting["deviations"].length !== 0){
          dataAnalysisString += `TRANSCRIPT DEVIATIONS DETECTED:\n\n\n`
          for (const deviation of session_reporting["deviations"]){
            dataAnalysisString += `PREVIOUS: ${deviation["old"]}\n\nCURRENT: ${deviation["new"]}\n\n\n`
          }
        } 
        if (session_reporting.hasOwnProperty("outcome_changed") && session_reporting.hasOwnProperty("explanation")){
          dataAnalysisString += `TRANSCRIPT DEVIATION SIGNIFIGANCE: ${session_reporting["outcome_changed"]}\n\n`
          dataAnalysisString += `LLM EXPLANATION: ${session_reporting["explanation"]}\n\n`
        } 
        newFormattedData[sessionID]["analysis"] = dataAnalysisString     
      }
    }
    newFormattedData["status"]["analysis"] = statusString
  }
  const getCurrentTimestamp = () => {
    const now = new Date();
  
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
  
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
  
    const timestamp = `${year}-${month}-${day}_${hours}-${minutes}`;
    return timestamp;
  };

  function convertTimestampToDate(timestamp) {
    var a = new Date(timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    return time;    
  }

export {
    formatTranscriptData,
    formatAnalysisData,
    formatTranscriptDatav1,
    formatAnalysisDatav1,
    getCurrentTimestamp,
    convertTimestampToDate, 
  }