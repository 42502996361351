import * as React from 'react';
import { 
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import { convertStringToText } from '../../utils/common';
const Analysis = (props) => {
  const {
    analysisOutput,
    selectedSession,
    handleDisplaySwitch,
    formattedData,
    AnalysisDivider,
  } = props;
  
  return (
    <Box sx={{ width: '100%' }}>
      {Object.keys(formattedData).length > 0 && (
        <ButtonGroup aria-label="outlined button group">
          {Object.keys(formattedData).map((key, index) => {
            const isFirstButton = index === 0;
            const isLastButton = index === Object.keys(formattedData).filter(key => key !== 'status' && key !=='usage').length - 1;

            return (
              key !== 'status' &&
              key !== 'usage' && (
                <Button
                  variant={selectedSession === key ? 'contained' : 'outlined'}
                  sx={{
                    boxShadow: 'none',
                    borderTopLeftRadius: isFirstButton ? '8px' : '0',
                    borderTopRightRadius: isLastButton ? '8px' : '0',
                    borderBottomLeftRadius: '0',
                    borderBottomRightRadius: '0',
                  }}
                  key={`status-${index}`}
                  label={key}
                  onClick={() => handleDisplaySwitch(key)}
                >
                  {key}
                </Button>
              )
              );
            })}
        </ButtonGroup>
      )}
      {
        ( analysisOutput.status ) && 
        <Card sx={{borderRadius: '8px'}}>
          <CardContent>
            {
              Object.entries(analysisOutput).map(([key, value], index) => 
                {
                  const isValueValid = value && value.length > 0;
                  const isLastValidElement = Object.entries(analysisOutput).slice(index + 1).every(([_nextKey, nextValue]) => !nextValue || nextValue.length === 0);
                  return (
                    <span key={`analysis-output-${index}`}>
                      { isValueValid && 
                        <>
                          <Typography variant="body2"> {`${convertStringToText(key)}: `} </Typography>
                          {['status', 'task', 'explanation', 'outcome_changed'].includes(key) && <Typography variant="body1">{convertStringToText(value)}</Typography>}
                          {['goal_accomplished', 'expected_result'].includes(key) && <Typography variant="body1">{value === "true" ? 'Yes' : 'No'} </Typography>}
                          {['deviation'].includes(key) && <Typography variant="body1">{value.map((dev, index) => (<span key={`dev-${index}`}>{`Old: ${dev["old"]}`} <br/><br/> {`New: ${dev["new"]}`} <br/><br/></span>))} </Typography>}
                          {!isLastValidElement && <AnalysisDivider/>}
                        </>
                      }
                    </span>
                  )
                }
            )}
          </CardContent>
        </Card>}
    </Box>
  );
}

export default Analysis;