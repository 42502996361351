const configUrl = "/config";
const verifyTranscriptUrl = "/verifyTranscript"
const resultsUrl = "/results";
const resumeUrl = "/test-utterance-at-node"
const validationUrl = "/validate-node";
const exportNodeUrl = "/export-node";
const exploreNodeUrl = "/explore-node";
const crawlFromNodeUrl = "/crawl-from-node";
const generateAIResponsesUrl = "/generate-ai-responses";
const exportChildrenUrl = "/export-children";
const stopBranchCrawlUrl = "/stop-branch-crawl";
const syncValidationTestCaseUrl = "/sync-validation-testcase";
const retranscribeWithLLMUrl = "/retranscribe-with-llm";
const deleteNodeUrl = "/delete-node";

const send_configuration_request = async (config, botConnectorUrl) => {
    let getConfigUrl = '';
    let jsonInput = {}
    if(config["config"] && (config["config"]["option"] === "directed_dialogue_crawler" || config["config"]["option"] === "natural_language_crawler")){
      getConfigUrl = "/crawl"
      jsonInput = config
    } else {
      getConfigUrl = configUrl
      jsonInput = {"config": config, "bot_connector_url": botConnectorUrl}
    }
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + getConfigUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonInput),
      });

      const responseBody = await response.json();
      return responseBody
    } catch (error) {
      console.error('Error sending request:', error);
      return {testID: "", status: "error"}
    }
  };

const send_verify_transcript_request = async (config, botConnectorUrl) => {
  let jsonInput = {"config": config, "bot_connector_url": botConnectorUrl}
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + verifyTranscriptUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    });

    const responseBody = await response.json();
    //console.log(responseBody)
    return responseBody
  } catch (error) {
    console.error('Error sending request:', error);
    return {testID: "", status: "error"}
  }
};

const send_results_request = async (params, option = "") => {
  let jsonInput = {};
  let getResultUrl = resultsUrl;
  switch (option) {
    case "directed_dialogue_crawler":
    case "natural_language_crawler":
      jsonInput = {
        testId: params,
        sessionId: "",
        format: "tekvisionJson",
        isCrawler: "true"
      };
      break;
    case "zip":
      jsonInput = {
        testId: params.testId,
        sessionId: params.sessionId,
        format: "zip",
        isCrawler: "true"
      };
      break;
    case "previewZip":
      jsonInput = {
        testId: params.testId,
        sessionId: params.sessionId,
        format: "tekvisionJson",
        isCrawler: "true"
      };
      break;      
    default:
      jsonInput = { "testId": params };
  }
  

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + getResultUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    });

    if (option === "zip") {
      await downloadZip(response);
      return { status: "success", message: "File is downloading" };
    } else {
      // Handle JSON response for other options
      const responseBody = await response.json();
      return responseBody;
    }
  } catch (error) {
    console.error('Error sending request:', error);
    return { status: "error", message: error.toString() };
  }
};

const downloadZip = async (response) => {
  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`);
  }

  // Get the filename from the Content-Disposition header
  const contentDisposition = response.headers.get('content-disposition');

  const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+?)"/);
  const filename = filenameMatch ? filenameMatch[1] : "downloaded-results.zip";

  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename; // Set the filename obtained from the backend
  document.body.appendChild(a);
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

const send_stop_crwal_request = async (batchId) => {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/stopCrawl", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({batchId: batchId}),
    });

    const responseBody = await response.json();
    return responseBody
  } catch (error) {
    console.error('Error sending request:', error);
    return {status: "error", message: error.toString()}
  }
}

const send_resume_request = async (batch_id, node_name, utterance) => {
  let jsonInput = {
    batch_id: batch_id,
    node_name: node_name,
    utterance: utterance,
  };

  let getResumeUrl = resumeUrl;
  

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + getResumeUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    });
    
    const responseBody = await response.json();
    return responseBody

  } catch (error) {
    console.error('Error sending request:', error);
    return { status: "error", message: error.toString() };
  }
};

const send_excel_request = async (testId, sessionId, format, isCrawler) => {
  let getResultUrl = resultsUrl;
  if (sessionId) {
    testId = sessionId;
  }
  let jsonInput = {
    testId: testId,
    sessionId: sessionId,
    format: format,
    isCrawler: isCrawler
  };

  try {
    // process.env.REACT_APP_BACKEND_URL
    let response = await fetch(process.env.REACT_APP_BACKEND_URL + getResultUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    })
    .then(response => {
      if (!response.ok) throw new Error('Network response was not ok');
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `DialogIQTestcases-${testId}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => console.error('Error:', error));

  } catch (error) {
    console.error('Error sending excel request:', error);
    return { status: "error", message: error.toString() };
  }
};

const send_lens_export_request = async (testId) => {
  let lensExportUrl = process.env.REACT_APP_BACKEND_URL + "/lens-export"

  let jsonInput = {
    testId: testId,
  };

  try {
    const response = await fetch(lensExportUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    });
    console.log(response.json())
  } catch (error) {
    console.error('Error sending request:', error);
    return { status: "error", message: error.toString() };
  }
}

const send_validation_request = async (batch_id, node_name) => {
  let jsonInput = {
    batch_id: batch_id,
    node_name: node_name,
  };

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + validationUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    });
    
    const responseBody = await response.json();
    return responseBody;

  } catch (error) {
    console.error('Error sending validation request:', error);
    return { status: "error", message: error.toString() };
  }
};

const send_export_node_request = async (batch_id, node_name) => {
  let jsonInput = {
    batch_id: batch_id,
    node_name: node_name,
  };

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + exportNodeUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    });
    
    const responseBody = await response.json();
    return responseBody;

  } catch (error) {
    console.error('Error sending export node request:', error);
    return { status: "error", message: error.toString() };
  }
};

const send_explore_node_request = async (batch_id, node_name) => {
  let jsonInput = {
    batch_id: batch_id,
    node_name: node_name,
  };

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + exploreNodeUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    });
    
    const responseBody = await response.json();
    return responseBody;

  } catch (error) {
    console.error('Error sending explore node request:', error);
    return { status: "error", message: error.toString() };
  }
};

const send_crawl_from_node_request = async (batch_id, node_name) => {
  let jsonInput = {
    batch_id: batch_id,
    node_name: node_name,
  };

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + crawlFromNodeUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    });
    
    const responseBody = await response.json();
    return responseBody;

  } catch (error) {
    console.error('Error sending crawl from node request:', error);
    return { status: "error", message: error.toString() };
  }
};

const send_generate_ai_responses_request = async (batch_id, node_name) => {
  let jsonInput = {
    batch_id: batch_id,
    node_name: node_name,
  };

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + generateAIResponsesUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    });
    
    const responseBody = await response.json();
    return responseBody;

  } catch (error) {
    console.error('Error sending generate AI responses request:', error);
    return { status: "error", message: error.toString() };
  }
};

const send_export_children_request = async (batch_id, node_name) => {
  let jsonInput = {
    batch_id: batch_id,
    node_name: node_name,
  };

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + exportChildrenUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    });
    
    const responseBody = await response.json();
    return responseBody;

  } catch (error) {
    console.error('Error sending export children request:', error);
    return { status: "error", message: error.toString() };
  }
};

const send_stop_branch_crawl_request = async (batch_id, node_name) => {
  let jsonInput = {
    batch_id: batch_id,
    node_name: node_name,
  };

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + stopBranchCrawlUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    });
    
    const responseBody = await response.json();
    return responseBody;

  } catch (error) {
    console.error('Error sending stop branch crawl request:', error);
    return { status: "error", message: error.toString() };
  }
};

const send_sync_validation_testcase_request = async (batch_id, node_name) => {
  let jsonInput = {
    batch_id: batch_id,
    node_name: node_name,
  };

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + syncValidationTestCaseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    });
    
    const responseBody = await response.json();
    return responseBody;

  } catch (error) {
    console.error('Error sending sync validation testcase request:', error);
    return { status: "error", message: error.toString() };
  }
};

const send_retranscribe_with_llm_request = async (batch_id, node_name) => {
  let jsonInput = {
    batch_id: batch_id,
    node_name: node_name,
  };

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + retranscribeWithLLMUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    });
    
    const responseBody = await response.json();
    return responseBody;

  } catch (error) {
    console.error('Error sending retranscribe with LLM request:', error);
    return { status: "error", message: error.toString() };
  }
};

const send_delete_node_request = async (batch_id, node_name) => {
  let jsonInput = {
    batch_id: batch_id,
    node_name: node_name,
  };

  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + deleteNodeUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonInput),
    });
    
    const responseBody = await response.json();
    return responseBody;

  } catch (error) {
    console.error('Error sending delete node request:', error);
    return { status: "error", message: error.toString() };
  }
};

export {
    send_configuration_request,
    send_results_request,
    send_verify_transcript_request,
    send_stop_crwal_request,
    send_resume_request,
    send_excel_request,
    send_lens_export_request,
    send_validation_request,
    send_export_node_request,
    send_explore_node_request,
    send_crawl_from_node_request,
    send_generate_ai_responses_request,
    send_export_children_request,
    send_stop_branch_crawl_request,
    send_sync_validation_testcase_request,
    send_retranscribe_with_llm_request,
    send_delete_node_request
}
