import React from 'react';
// import Header from './components/Header';
// import ConfigColumn from './components/ConfigColumn';
// import ResultsColumn from './components/ResultsColumn';
// import Footer from './components/Footer';
import Header from '../components/Header';
import ConfigColumn from '../components/ConfigColumn';
import ResultsColumn from '../components/ResultsColumn';
import Footer from '../components/Footer';
import './styles/default.scss'; // Import the SCSS file


function Default() {
  return (
    <>   
      <div className="container">
        <Header />
        <div className="body">
          <div className="configContainer">
            <ConfigColumn />
          </div>
          <ResultsColumn />
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default Default;