import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { renderConfigLayout } from '../../utils/common';
import { convertStringToText } from '../../utils/common';
import { Button } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#eeeeee",
    color: theme.palette.common.black,
    // fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 16,
  },
}));

export default function Tables(props) {
  const {
    rows,
    tableKey,
    headers,
    configJson,
    setConfigJson,
    setConfigLayout,    
  } = props

  useEffect(() => {
    const convertedConfigJson = { [tableKey]: [] };
    rows.forEach((card) => {
      const rowData = {};
      card.forEach((cell) => {
        const { key, value } = cell.content;
          switch (cell.type) {
            case 'input':
            case 'dropdown':
            case 'tags':
              return rowData[key] = value;
          }
      });
  
      if (Object.keys(rowData).length > 0) {
        convertedConfigJson[tableKey].push(rowData);
      }
    });
  
    setConfigJson && setConfigJson((prevConfigJson) => ({
      ...prevConfigJson,
      [tableKey]: convertedConfigJson[tableKey],
    }));
  }, []);

  const handleRemoveRow = (index) => {
    setConfigLayout((prevConfigJson) => {
      const updatedConfigJson = prevConfigJson.map((config) => {
        if (config.type === 'table' && config.content.key === tableKey) {
          config.content.rows = config.content.rows.filter((_, currentIndex) => currentIndex !== index);
        }
        return config;
      });
      return updatedConfigJson;
    });

    setConfigJson((prevConfigJson) => ({
      ...prevConfigJson,
      [tableKey]: prevConfigJson[tableKey].filter((_, currentIndex) => currentIndex !== index),
    }));
  }

  const handleAddRow = (fields) => {
    const convertedConfigJson = { [tableKey]: [] };
    const rowData = {};
    fields.forEach(field => {
      const { type } = field;
      if(field['content']){
        const { key, value } = field['content'];
        if (type === 'input') {
          rowData[key] = value;
        } else if (type === 'dropdown') {
          rowData[key] = value;
        }
      }
    })
    if (Object.keys(rowData).length > 0) {
      convertedConfigJson[tableKey].push(rowData);
    }

    setConfigLayout((prevConfigJson) => {
      const updatedConfigJson = prevConfigJson.map((config) => {
        if (config.type === 'table' && config.content.key === tableKey) {
          const rowsArray = config.content.rows;
          const addFieldIndex = rowsArray.findIndex((row) => row[0].type === 'add-row');
          if (addFieldIndex >= 0) {
            rowsArray.splice(addFieldIndex, 0, fields);
          }
        }
        return config;
      });
      return updatedConfigJson;
    });
    
    setConfigJson((prevConfigJson) => ({
      ...prevConfigJson,
      [tableKey]: [...prevConfigJson[tableKey], ...convertedConfigJson[tableKey]],
    }));
  }

  return (
    <>
      <h4>{convertStringToText(tableKey)}</h4>
      <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: 4 }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
            <TableBody>
              {rows.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {row.map((cell, cellIndex) => (
                      <StyledTableCell key={cellIndex} align="left">
                        {
                          renderConfigLayout(
                          {
                            key: cell['content']['key'] || cellIndex,
                            value: cell,
                            index: rowIndex,
                            configJson: configJson,
                            setConfigJson,
                            setConfigLayout,
                            parentKey: tableKey,
                            parentType: 'table',
                          })}
                        {cell.type === 'add-row' && (
                          <Button onClick={() => handleAddRow(cell['content'], rowIndex)} variant="contained" color="primary">
                            Add Row
                          </Button>
                        )}
                      </StyledTableCell>
                    ))}
                    {row.every((cell) => cell.type !== 'add-row') && (
                      <StyledTableCell key={rowIndex} align="left">
                        <IconButton
                          onClick={() => handleRemoveRow(rowIndex)}
                          aria-label="delete"
                          size="small"
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      </StyledTableCell>
                    )}
                  </TableRow>

              ))
            }
            </TableBody>
          </Table>
        </TableContainer>  
      </Paper>  
    </>
  );
}