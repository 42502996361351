import * as React from 'react';
import { 
  Accordion, 
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  Button,
  IconButton,
 } from "@mui/material";
 import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { convertStringToText } from "../../utils/common";
import './AnalysisAndTranscript.scss'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
const BatchSummary = (props) => {
  const {
    analysisOutput,
    isSummaryExpanded,
    setSummaryExpanded,
    handleDisplaySwitch,
    configJson,
    AnalysisDivider,
    handleRunTesterExpress,
  } = props;
  const configOption = (configJson && configJson['option']) || "";
  const totalRequestedRun = 0;
  const testID = analysisOutput && (analysisOutput["testID"] || analysisOutput["batchId"]) || "";
  const handleChangeSummaryExpansion = (_panel) => (_e, isExpanded) => {
    setSummaryExpanded(isExpanded);
    handleDisplaySwitch("status")
  };

  return (
    <Box sx={{ width: '100%', marginBottom: '1rem'}}>
       <div className='config-details'>
        <div className='header-action'>
          <div className='w-60'>Batch Summary </div>
          <div className='execution-status'>
          {/* {analysisOutput.executingTestCases && analysisOutput.executingTestCases.length > 0 ? (
                analysisOutput.executingTestCases.map((testCase, index) => (
                  <span key={index}><Typography>{testCase}</Typography></span>
                ))
            ) : (
              <Typography variant="body2" sx={{ ml: 2 }}>No executing test cases</Typography>
            )} */}
          </div>
        </div>
       
      </div>
      <Box sx={{pl:2}}>
          <div className='accordion-details-container'>
            <div className='accordion-details-column'>
              <Typography>{testID ? `Batch ID: ${testID}` : "No Summary"}</Typography>
              {
                ['NLU_tester', 'regression_testing'].includes(configOption) && (
                  <>
                    {testID && <Typography>Batch Status: {analysisOutput["testInProgress"] === true ? "In Progress" : "Completed"}</Typography>}
                    {analysisOutput["tokenUsed"] && (
                      <>
                        <Typography>Completion Tokens: {analysisOutput["tokenUsed"]["completion_tokens"]}</Typography>
                        <Typography>Prompt Tokens: {analysisOutput["tokenUsed"]["prompt_tokens"]}</Typography>
                        <Typography>Total Tokens: {analysisOutput["tokenUsed"]["total_tokens"]}</Typography>
                      </>
                    )}
                  </>
                )
              }
              { 
                ['directed_dialogue_crawler', 'natural_language_crawler'].includes(configOption) && testID &&
                <>
                  <Typography>Batch Start Time: {analysisOutput["batchStartTime"]}</Typography>
                  <Typography>Last Updated Time: {analysisOutput["lastUpdatedTime"]}</Typography>
                  <Typography>Testcase Execution Count: {analysisOutput["sessionCounter"]}</Typography>
                  {/* {
    "batchId": "mockBatch",
    "sessionId": "mockSession5",
    "lastUpdatedTime": "April 15, 1900, 20:01:00",
    "status": "running",
    "batchStartTime": "April 15, 1900, 20:00:00",
    "sessionCounter": "5",
    "activeBatchList": [
        "mockBatch",
        "mockBatch2"
    ] */}
                  
                </>
              }

            </div>
            <div className='accordion-details-column'>
              {configOption === 'NLU_tester' && (
                <>
                  <Typography>Total Number of Tests: {totalRequestedRun}</Typography>
                  {analysisOutput && (
                    <>
                      {analysisOutput["totalTestRun"] !== undefined && (<Typography>Total Test Run: {analysisOutput["totalTestRun"]}</Typography>)}
                      {analysisOutput["totalGoalAccomplished"] !== undefined && (<Typography>Total Goal Accomplished: {analysisOutput["totalGoalAccomplished"]}</Typography>)}
                      {analysisOutput["totalExpectedResultReached"] !== undefined && (<Typography>Total Expected Result Reached: {analysisOutput["totalExpectedResultReached"]}</Typography>)}
                    </>
                  )}
                </>
              )}
              {configOption === 'regression_testing' && 
                <>
                  { analysisOutput["sessions"] && 
                    <>
                      {analysisOutput["totalTestRun"] !== undefined && <Typography>Total Test Run: {analysisOutput["totalTestRun"]}</Typography>}
                      <Typography>Total Exact Match: {analysisOutput['sessions'].reduce((acc, session) => session["outcome_changed"] === "none" ? acc + 1 : acc, 0)}</Typography>
                      <Typography>Total Minor Deviation: {analysisOutput['sessions'].reduce((acc, session) => session["outcome_changed"] === "minor" ? acc + 1 : acc, 0)}</Typography>
                      <Typography>Total Major Deviation: {analysisOutput['sessions'].reduce((acc, session) => session["outcome_changed"] === "major" ? acc + 1 : acc, 0)}</Typography>
                    </>
                  }
                </>
              }
            </div>
          </div>
          <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Test Case Execution Status</Typography>
          <div>
            <Typography variant="subtitle1">Executing Test Cases:</Typography>
            {analysisOutput.executingTestCases && analysisOutput.executingTestCases.length > 0 ? (
              <ul style={{ marginTop: 0, paddingLeft: '1.5rem' }}>
                {analysisOutput.executingTestCases.map((testCase, index) => (
                  <li key={index}><Typography>{testCase}</Typography></li>
                ))}
              </ul>
            ) : (
              <Typography variant="body2" sx={{ ml: 2 }}>No executing test cases</Typography>
            )}
          </div>
          <div style={{ marginTop: '1rem' }}>
            <Typography variant="subtitle1">Pending Test Cases:</Typography>
            {analysisOutput.pendingTestCases && analysisOutput.pendingTestCases.length > 0 ? (
              <ul style={{ marginTop: 0, paddingLeft: '1.5rem' }}>
                {analysisOutput.pendingTestCases.map((testCase, index) => (
                  <li key={index}><Typography>{testCase}</Typography></li>
                ))}
              </ul>
            ) : (
              <Typography variant="body2" sx={{ ml: 2 }}>No pending test cases</Typography>
            )}
          </div>
          <div className='config-details mr--15'>
            <div className='header-action'>
              <div>Mapping </div>
              {/* <div><Button  variant="contained" color="primary" onClick={()=>handleRunTesterExpress()}><PlayCircleOutlineIcon />{'  '} Run</Button></div> */}
              <div>  
                {/* <IconButton
                  
                  title="run batch"
                  aria-label="run test"
                  color="primary"
                >
                  <DownloadForOfflineIcon style={{fill:'rgba(0, 106, 104, 1)',fontSize:34}} />
                </IconButton>
                 */}
                </div>
            </div>
          </div>
          {analysisOutput["sessions"] && analysisOutput["sessions"].length > 0 && (
            <>
              <AnalysisDivider/>
              {
                analysisOutput["sessions"] && analysisOutput["sessions"].length > 0 && analysisOutput["sessions"].map((session, index) => 
                  <>
                    <Typography>Session Id: {session["session_id"]}</Typography>
                    <Typography>Session Task: {convertStringToText(session["session_task"])}</Typography>
                    <Typography>Session Status: {session["session_status"]}</Typography>
                    {configOption === 'NLU_tester' && <Typography>Goal Accomplished: {session["goal_accomplished"] === "true" ? 'Yes' : 'No'}</Typography>}
                    {configOption === 'NLU_tester' && <Typography>Expected Result: {session["expected_result"] === "true" ? 'Yes' : 'No'}</Typography>}
                    {configOption === 'regression_testing' && session["outcome_changed"] && <Typography>Significance of Deviation: {convertStringToText(session["outcome_changed"])}</Typography>}
                    {index !== analysisOutput["sessions"].length - 1 && <AnalysisDivider/>}
                  </>
                )
              }              
            </>
          )
          }
    </Box>
    </Box>
  );
}

export default BatchSummary;