import Dropdown from '../components/ConfigTypes/Dropdown';
import Cards from '../components/ConfigTypes/Cards';
import SelectCards from '../components/ConfigTypes/SelectCards';
import TextInput from '../components/ConfigTypes/TextInput';
import InputGroup from '../components/ConfigTypes/InputGroup';
import Tables from '../components/ConfigTypes/Tables';
import Tags from '../components/ConfigTypes/Tags';

const regressionTestingFields = [
  'option', 
  'application_type', 
  'verify_test_id', 
  'verify_session_id', 
  'analysis_gpt_version', 
  'connector', 
  'bot_connector_url',
  "other",
  "phone_number",
]

const nluTesterFields = [
  "option",
  "test_account_info",
  "application_type",
  "task_list",
  "task_instructions",
  "custom_instructions",
  "conversation_gpt_version",
  "analysis_gpt_version",
  "connector",
  "bot_connector_url",
  "phone_number",
  "other",
  "description",
  "value",
  "applicability",
  "task_name",
  "goal",
  "expected_result",
  "num_variations",
]

const dtmfCrawlerFields = [
  "application_type",
  "option",
  "connector",
  "bot_connector_url",
  // "wrapper_id",
  "initial_user_messages",
  "other",
  "test_account_info",
  "phone_number",
  "batch_id",
  "description",
  "value",
  "applicability",  
]

const naturalLanguageCrawlerFields = [
  "application_type",
  "bot_connector_url",
  // "wrapper_id",
  "nlu_intents",
  "other",
  "response_count_per_intent",
  "port_limit",
  "max_crawl_depth",
  "test_account_info",
  "phone_number",
  "description",
  "value",
  "applicability",  
]

const customWordConverter = (inputString) => {
  inputString = inputString.replace("gpt_version", "LLM")
  inputString = inputString.replace("outcome_changed", "significance_of_deviation")
  return inputString;
}

export const convertStringToText = (inputString, showEmpty=false) => {
  if(showEmpty) return '';
  if(Array.isArray(inputString)){
    let result = []
    for(let word of inputString){
      word = word.split('_');
      let capitalizedWords = word.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      capitalizedWords.join(' ')
      result.push(capitalizedWords.join(' '))
    }
    return result.join(', ')
  } else {
    inputString = customWordConverter(inputString)
    const words = inputString.split('_');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  }
}

export const renderConfigLayout = (props) => {
  const {
    key,
    value,
    index,
    parentType='',
    parentKey='',
    isReadOnly=false,
    configJson={},
    setConfigJson,
    setConfigLayout,
  } = props

  const renderingLayout = () => {
    switch (value['type']) {
      case 'dropdown':
        return (
          <Dropdown
            key={`${key}-${index}`}
            configKey={value['content']['key']}
            options={value['content']['options']}
            configValue={value['content']['value']}
            isMultiple={value['content']['isMultiple']}
            setConfigJson={setConfigJson}
            setConfigLayout={setConfigLayout}
            parentType={parentType}
            parentKey={parentKey}
            index={index}
          />
        );
      case 'tags':
        return (
          <Tags
            key={`${key}-${index}`}
            configKey={value['content']['key']}
            options={value['content']['options']}
            configValue={value['content']['value']}
            isMultiple={value['content']['isMultiple']}
            configJson={configJson}
            setConfigJson={setConfigJson}
            setConfigLayout={setConfigLayout}
            parentType={parentType}
            parentKey={parentKey}
            index={index}
          />
        );
      case 'cards':
        return (
          <Cards
            key={`${key}-${index}`}
            cards={value['content']['cards']}
            cardKey={value['content']['cardKey']}
            setConfigJson={setConfigJson}
            setConfigLayout={setConfigLayout}
          />
        );
      case 'select-cards':
        return (
          <SelectCards
            key={`${key}-${index}`}
            cards={value['content']['cards']}
            cardKey={value['content']['key']}
            selectableList={value['content']['selectableList']}
            selectableKey={value['content']['selectableKey']}
            setConfigJson={setConfigJson}
            setConfigLayout={setConfigLayout}
          />
        );
      case 'input':
        return (
          <TextInput
            key={`${key}-${index}`}
            configKey={value['content']['key']}
            index={index}
            configValue={value['content']['value']}
            setConfigJson={setConfigJson}
            setConfigLayout={setConfigLayout}
            parentType={parentType}
            parentKey={parentKey}
            isReadOnly={isReadOnly}
            name={key}
          />
        );
      case 'input-group':
        return (
          <InputGroup
            key={`${key}-${index}`}
            configKey={value['content']['key']}
            fields={value['content']['fields']}
            setConfigJson={setConfigJson}
            setConfigLayout={setConfigLayout}
          />
        );
      case 'table':
        return (
          <Tables
            key={`${key}-${index}`}
            rows={value['content']['rows']}
            tableKey={value['content']['key']}
            headers={value['content']['headers']}
            configJson={configJson}
            setConfigJson={setConfigJson}
            setConfigLayout={setConfigLayout}
          />
        );
      default:
        return null;
    }
  }

  const taskOption = configJson['option'] || 'NLU_tester';
  const fieldKey = value['content']['key'];
  const fieldMappings = {
    'regression_testing': regressionTestingFields,
    'NLU_tester': nluTesterFields,
    'directed_dialogue_crawler': dtmfCrawlerFields,
    'natural_language_crawler': naturalLanguageCrawlerFields
  };
  
  if (fieldMappings[taskOption]?.includes(fieldKey)) {
    return renderingLayout();
  }
};

export const convertRawJsonToLayoutJson = (original) => {
  const modified = [];

  // Convert application_type
  const applicationType = {
    type: 'dropdown',
    content: {
      key: 'application_type',
      options: [original.application_type],
      value: [original.application_type],
    },
  };
  modified.push(applicationType);

  // Convert option
  const option = {
    type: 'dropdown',
    content: {
      key: 'option',
      options: ['NLU_tester', 'regression_testing', "directed_dialogue_crawler", "natural_language_crawler"],
      value: [original.option],
    },
  };
  modified.push(option);

  // Convert connector
  const connector = {
    type: 'dropdown',
    content: {
      key: 'connector',
      options: ['omilia', 'dialogflow', 'tekvision'],
      value: [original.connector],
    },
  };
  modified.push(connector);

  // Convert bot_connector_url
  const botConnectorUrl = {
    type: 'input',
    content: {
      key: 'bot_connector_url',
      value: original.bot_connector_url,
    },
  };
  modified.push(botConnectorUrl);

  // Convert conversationChatGptVersion
  const conversationChatGptVersion = {
    type: 'dropdown',
    content: {
      key: 'conversation_gpt_version',
      options: ["gpt3.5", "gpt4"],
      value: [original.conversation_gpt_version],
    },
  };
  modified.push(conversationChatGptVersion);
  
  // Convert analysis_gpt_version
  const analysisGptVersion = {
    type: 'dropdown',
    content: {
      key: 'analysis_gpt_version',
      options: ["gpt3.5", "gpt4"],
      value: [original.analysis_gpt_version],
    },
  };
  modified.push(analysisGptVersion);

  // Convert phoneNumber
  const phoneNumber = {
    type: 'input',
    content: {
      key: 'phone_number',
      value: original.phone_number,
    },
  };
  modified.push(phoneNumber);

  // Convert batch_id
  const batchId = {
    type: 'input',
    content: {
      key: 'batch_id',
      value: original.batch_id,
    },
  };
  modified.push(batchId);

  // Convert wrapper_id
  // const wrapperId = {
  //   type: 'input',
  //   content: {
  //     key: 'wrapper_id',
  //     value: original.wrapper_id,
  //   },
  // };

  // modified.push(wrapperId);
  
  // Convert initial_user_messages
  const initialUserMessages = {
    type: 'tags',
    content: {
      key: 'initial_user_messages',
      options: [],
      value: original.initial_user_messages,
      isMultiple: true,
    },    
  };
  modified.push(initialUserMessages);

  // Convert other
  const other = {
    type: 'input',
    content: {
      key: 'other',
      value: "test",
    },
  };
  modified.push(other);

  // Convert verify_test_id
  const verifyTestId = {
    type: 'input',
    content: {
      key: 'verify_test_id',
      value: original.verify_test_id,
    },
  };
  modified.push(verifyTestId);

  // Convert verify_session_id
  const verifySessionId = {
    type: 'tags',
    content: {
      key: 'verify_session_id',
      options: [],
      value: original.verify_session_id,
      isMultiple: true,
    },    
  };
  modified.push(verifySessionId);


  
  
    // Convert task_instructions
  const taskInstructions = {
    type: 'select-cards',
    content: {
      selectableKey: 'task_list',
      selectableList: original.task_list,
      key: 'task_instructions',
      cards: [],
    },
  };
  for (const task of original.task_instructions) {
    const card = [
        {
          type: 'input',
          content: {
            key: 'task_name',
            value: task.task_name,
          },
        },
        {
          type: 'input',
          content: {
            key: 'goal',
            value: task.goal,
          },
        },
        {
          type: 'input',
          content: {
            key: 'expected_result',
            value: task.expected_result,
          },
        },
        {
          type: 'input',
          content: {
            key: 'num_variations',
            value: task.num_variations,
          },
        },
      ]
      taskInstructions.content.cards.push(card);
    }
  const testInstructionNewRow = [
    {
      type: "add-card",
      content: [
          {
            type:"input",
            content: {
                key: "task_name",
                value: ""
            }
          }, 
          {
            type: "input",
            content: {
                key: "goal",
                value: ""
            }
          },
          {
          type: "input",
          content: {
              key: "expected_result",
              value: ""
          }
          },
          {
          type: "input",
          content: {
              key: "num_variations",
              value: ""
          }
        },
      ]
    }    
  ]
  taskInstructions.content.cards.push(testInstructionNewRow);
  modified.push(taskInstructions);

  
  // Convert test_account_info
  const testAccountInfo = {
    type: 'table',
    content: {
      key: 'test_account_info',
      headers: ['description', 'value', 'applicability', ''],
      rows: [],
    },
  };
  original.test_account_info.forEach((info) => {
    const row = [
      {
        type: 'input',
        content: {
          key: 'description',
          value: info.description,
        },
      },
      {
        type: 'input',
        content: {
          key: 'value',
          value: info.value,
        },
      },
      {
        type: 'tags',
        content: {
          key: 'applicability',
          options: [
            'global',
            'savings_account_balance',
            'loan_payment',
            'external_loan_payment',
            'loan_advance',
          ],
          value: info.applicability,
          isMultiple: true,
        },
      },
    ];
    testAccountInfo.content.rows.push(row);
  });
  const testAccountInfoNewRow = [
    {
      type: "add-row",
      content: [
         {
            type:"input",
            content: {
               key: "description",
               value:""
            }
         }, 
         {
            type: "input",
            content: {
               key: "value",
               value: ""
            }
         },
         {
            type:"tags",
            content:{
               key: "applicability",
               options: [
                  "global",
                  "savings_account_balance",
                  "loan_payment",
                  "external_loan_payment",
                  "loan_advance"
               ],
               value: [],
               isMultiple: true
            }
         }                        
      ]
    }    
  ]
  
  testAccountInfo.content.rows.push(testAccountInfoNewRow);
  modified.push(testAccountInfo);

  // Convert custom_instructions
  const customInstructions = {
    type: 'input-group',
    content: {
      key: 'custom_instructions',
      fields: [],
    },
  };
  original.custom_instructions.forEach((instruction) => {
    const field = {
      type: 'input',
      content: {
        key: '',
        value: instruction,
      },
    };
    customInstructions.content.fields.push(field);
  });
  const customInstructionsAddField = {
    type: "add-field",
    content: {
      type: "input",
      content:{
          key: "",
          value: ""
      }
    }
  }  
  customInstructions.content.fields.push(customInstructionsAddField)
  modified.push(customInstructions);

  return modified;
}
