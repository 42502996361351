import React from 'react';

function Header() {
  return (
    <div id="header" className="container header">
      <h1>Connex LLM Automated Testing</h1>
    </div>
  );
}

export default Header;