import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";
import CollapsibleSidebar from '../components/Sidebar/CollapsibleSidebar';
import routes from "../routes";
import Configurations from './Configuration';
import AnalysisAndTranscript from './AnalysisAndTranscript';
import ConfigLayout from '../configLayout.json';
import Download from './Download';
import { Typography } from '@mui/material';
import profile from "../assets/profile.svg";
import logout from "../assets/logout.svg";

export default function Home(props) {
  const { ...rest } = props;
  const location = useLocation();
  const drawerWidth = 260;
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Configuration");
  const [configJson, setConfigJson] = useState({});
  const [views, setViews] = useState("configuration")
  const [analysisOutput, setAnalysisOutput] = useState("");
  const [transcriptOutput, setTranscriptOutput] = useState("");
  const [testID, setTestID] = useState("");
  const [testInProgress, setTestInProgress] = useState(false);
  const [formattedData, setFormattedData] = useState({});
  const [selectedSession, setSelectedSession] = useState("status");
  const [configLayout, setConfigLayout] = useState(ConfigLayout);
  const [downloadParams, setDownloadParams] = useState({sessionId: "", testId: ""});
  const [selectedTreeNode, setSelectedTreeNode] = useState({})
  const [isDtmlCallStarted, setDtmlCallStarted] = useState(false);
  const [intentTree, setIntentTree] = useState({});
  //const [isDownloadDisabled, setIsDownloadDisabled] = useState(true);
  const [graphStatus, setGraphStatus] = useState("d3graph");
  const [runningBatch, setRunningBatch] = useState(false);
  const [inputBatchId, setInputBatchId] = useState({"testId": "", "sessionId": ""});
  const [colorMap, setColorMap] = useState({"Default": "lightgrey"});
  const [darkMode, setDarkMode] = useState(false);
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/v2") {
        return ( <Route path={`/${prop.path}`} element={prop.component} key={key} /> );
      } else {
        return null;
      }
    });
  };

  return (
      <Box sx={{ display: 'flex' }}>
      {isMobile ? (
        <CollapsibleSidebar drawerWidth={drawerWidth} onClose={() => setOpen(false)} setViews={setViews}/>) : (
        <Sidebar drawerWidth={drawerWidth} onClose={() => setOpen(false)} setViews={setViews}/>)}
      <Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth + 48}px)` } }}>
        {/* <Routes>
          {getRoutes(routes)}
          <Route
            path="/"
            element={
              <Navigate to="" replace />
            }
          />
        </Routes> */}
        <div className='app-header'>
          <div className='config'>

          </div>
          <div className='user-profile'>
               <div className='user-menu-flex'>
                <img src={logout} alt="logout" />
                <img src={profile} alt="user" />
                <Typography>Demo User</Typography>
               </div>
          </div>
        </div>
        {views === 'configuration' && 
          <Configurations
            configJson={configJson}
            setConfigJson={setConfigJson}
            configLayout={configLayout}
            setConfigLayout={setConfigLayout}
            graphStatus={graphStatus}
            setGraphStatus={setGraphStatus}
            setViews={setViews}
          />
        }
        {views === 'run' &&  
          <AnalysisAndTranscript
            run={true}
            analysisOutput={analysisOutput}
            transcriptOutput={transcriptOutput}
            testID={testID}
            testInProgress={testInProgress}
            formattedData={formattedData}
            intentTree={intentTree}
            selectedTreeNode={selectedTreeNode}
            selectedSession={selectedSession}
            isDtmlCallStarted={isDtmlCallStarted}
            setAnalysisOutput={setAnalysisOutput}
            setTranscriptOutput={setTranscriptOutput}
            setTestID={setTestID}
            setTestInProgress={setTestInProgress}
            setFormattedData={setFormattedData}
            setSelectedSession={setSelectedSession}
            setConfigJson={setConfigJson}
            setIntentTree={setIntentTree}
            setSelectedTreeNode={setSelectedTreeNode}
            setDtmlCallStarted={setDtmlCallStarted}
            graphStatus={graphStatus}
            setGraphStatus={setGraphStatus}
            runningBatch={runningBatch}
            setRunningBatch={setRunningBatch}
            inputBatchId={inputBatchId}
            setInputBatchId={setInputBatchId}
            colorMap={colorMap}
            setColorMap={setColorMap}
            darkMode={darkMode}
            setDarkMode={setDarkMode}
          />}

        {views === 'analysis-and-transcript' && 
          <AnalysisAndTranscript
            analysisOutput={analysisOutput}
            transcriptOutput={transcriptOutput}
            testID={testID}
            testInProgress={testInProgress}
            formattedData={formattedData}
            intentTree={intentTree}
            selectedTreeNode={selectedTreeNode}
            selectedSession={selectedSession}
            isDtmlCallStarted={isDtmlCallStarted}
            setAnalysisOutput={setAnalysisOutput}
            setTranscriptOutput={setTranscriptOutput}
            setTestID={setTestID}
            setTestInProgress={setTestInProgress}
            setFormattedData={setFormattedData}
            setSelectedSession={setSelectedSession}
            setConfigJson={setConfigJson}
            setIntentTree={setIntentTree}
            setSelectedTreeNode={setSelectedTreeNode}
            setDtmlCallStarted={setDtmlCallStarted}
            graphStatus={graphStatus}
            setGraphStatus={setGraphStatus}
            runningBatch={runningBatch}
            setRunningBatch={setRunningBatch}
            inputBatchId={inputBatchId}
            setInputBatchId={setInputBatchId}
            colorMap={colorMap}
            setColorMap={setColorMap}
            darkMode={darkMode}
            setDarkMode={setDarkMode}
          />}
        {views === 'download' && 
          <Download
            intentTree={intentTree}
            selectedTreeNode={selectedTreeNode}
            downloadParams={downloadParams}
            setIntentTree={setIntentTree}
            setSelectedTreeNode={setSelectedTreeNode}
            setDownloadParams={setDownloadParams}
            graphStatus={graphStatus}
            setGraphStatus={setGraphStatus}
            setViews={setViews}
          />}
      </Box>
    </Box>   
  );
}
