import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import { convertStringToText, renderConfigLayout } from '../../utils/common';
import './styles/configTypes.scss'
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

const SelectCards = (props) => {
  const { 
    cards,
    selectableKey, //task_list
    selectableList,
    cardKey='', // task_instruction
    setConfigLayout,
    setConfigJson
  } = props;

  // set selectedCards into object in the form 
  // { index : Selected Key
  //   0     : Saving Account Info
  // }
  const findMatchingObjects = (selectableList, cards) => {
    const result = [];
  
    cards.forEach((subArr, index) => {
      subArr.forEach((obj) => {
        if (
          obj.type === "input" &&
          obj.content.key === "task_name" &&
          selectableList.includes(obj.content.value)
        ) {
          const newObj = {};
          newObj[index] = obj.content.value;
          result.push(newObj);
        }
      });
    });
  
    return result;
  }
  
  const [selectedCards, setSelectedCards] = useState(findMatchingObjects(selectableList, cards));
  const [isReadOnly, setIsReadOnly] = useState([]);

  
  useEffect(() => {
    const convertedConfigJson = { [cardKey]: [] };
    cards.forEach((card) => {
      isReadOnly.push(true);
      const cardData = {};
      card.forEach((cardElement, index) => {
        const { key, value } = cardElement.content;
        if (cardElement.type === 'input') {
          cardData[key] = value;
        } else if (cardElement.type === 'dropdown') {
          cardData[key] = value;
        }
      });
  
      if (Object.keys(cardData).length > 0) {
        convertedConfigJson[cardKey].push(cardData);
      }
    });
  
    setConfigJson && setConfigJson((prevConfigJson) => ({
      ...prevConfigJson,
      [cardKey]: convertedConfigJson[cardKey],
    }));

    setConfigJson && setConfigJson((prevConfigJson) => ({ 
      ...prevConfigJson, 
      [selectableKey]: selectedCards.map((obj) => Object.values(obj)[0])
    }));    
  }, []);
  
  const cardContainerStyles = (index) => {
    if(index === cards.length - 1) {
      return{
        background: 'white',
        height: '4rem'
      }
    }
    if(selectedCards){
      const isSelected = selectedCards.some((obj) => Object.keys(obj).includes(String(index)))
      return {
        background: isSelected ? 'rgb(235 255 234)' : '',
        opacity: isSelected ? '100%' : '60%',
        position: 'relative',
      };
    }
  };


  const handleSelectCard = (card, index) => {
    if(card[0].type === 'add-card' || !isReadOnly[index]) {
      return
    }
    let selectedCardKey = getKeyFromCard(card);
    const foundIndex = selectedCards.findIndex((obj) => obj.hasOwnProperty(index));
    if (foundIndex !== -1) {
      if (selectedCards[foundIndex][index] === selectedCardKey) {
        const newArr = selectedCards.filter((obj, i) => i !== foundIndex);
        setSelectedCards(newArr);
      }
    } else {
      const newObj = {};
      newObj[index] = selectedCardKey;
      setSelectedCards([...selectedCards, newObj]);
    }

  };

  useEffect(() => {
    setConfigJson && setConfigJson((prevConfigJson) => ({
      ...prevConfigJson,
      [selectableKey]: selectedCards.map((obj) => Object.values(obj)[0]),
    }));

    setConfigLayout((prevConfigJson) => {
      const updatedConfigJson = prevConfigJson.map((config) => {
        if (config.type === 'select-cards' && config.content.key === cardKey) {
          config.content.selectableList = selectedCards.map((obj) => Object.values(obj)[0])
        }
        return config;
      });
      return updatedConfigJson;
    });    
  }, [selectedCards]);

  const handleRemoveCard = (index) => {
    isReadOnly.filter((_, currentIndex) => currentIndex !== index)
    setConfigLayout((prevConfigJson) => {
      const updatedConfigJson = prevConfigJson.map((config) => {
        if (config.type === 'select-cards' && config.content.key === cardKey) {
          config.content.cards = config.content.cards.filter((_, currentIndex) => currentIndex !== index);
        }
        return config;
      });
      return updatedConfigJson;
    });

    setConfigJson((prevConfigJson) => ({
      ...prevConfigJson,
      [cardKey]: prevConfigJson[cardKey].filter((_, currentIndex) => currentIndex !== index),
    }));

    // remove from selected and update index of remaining selected cards
    const updatedIndexCards = selectedCards.reduce((acc, obj) => {
      const key = Object.keys(obj)[0];

      if (parseInt(key) === index) {
        return acc; // Skip the card that should be removed
      }

      if (parseInt(key) > index) {
        const updatedKey = parseInt(key) - 1;
        acc.push({ [updatedKey]: obj[key] });
      } else {
        acc.push(obj);
      }

      return acc;
    }, []);

    setSelectedCards(updatedIndexCards);    
  }

  const handleAddCard = (fields, index) => {

    const convertedConfigJson = { [cardKey]: [] };
    const cardData = {};
    fields.forEach(field => {
      const { type } = field;
      const { key, value } = field['content'];
      if (type === 'input') {
        cardData[key] = value;
      } else if (type === 'dropdown') {
        cardData[key] = value;
      }
    })
    if (Object.keys(cardData).length > 0) {
      convertedConfigJson[cardKey].push(cardData);
    }

    setConfigLayout((prevConfigJson) => {
      const updatedConfigJson = prevConfigJson.map((config) => {
        if (config.type === 'select-cards' && config.content.key === cardKey) {
          const cardsArray = config.content.cards;
          const addFieldIndex = cardsArray.findIndex((card) => card[0].type === 'add-card');
          if (addFieldIndex >= 0) {
            cardsArray.splice(addFieldIndex, 0, fields);
          }
        }
        return config;
      });
      return updatedConfigJson;
    });
    
    setConfigJson((prevConfigJson) => ({
      ...prevConfigJson,
      [cardKey]: [...prevConfigJson[cardKey], ...convertedConfigJson[cardKey]],
    }));
  }


  const getKeyFromCard = (card) => {
    const field = card.find(
      (field) => field['content'] && field['content']['key'] === 'task_name'
    );
  
    if (field && field['content']) {
      return field['content']['value'];
    } else {
      return 'add-card'
    }
  };

  const handleSetIsReadOnly = (index) => {
    setIsReadOnly((prevState) => {
      const updatedIsReadOnly = [...prevState]; // Create a copy of the original array
      updatedIsReadOnly[index] = !prevState[index]; // Update the element at the specified index
      return updatedIsReadOnly; // Return the updated array
    });

    const foundIndex = selectedCards.findIndex((obj) => obj.hasOwnProperty(index));
    if (foundIndex !== -1) {
      const newArr = selectedCards.filter((_, i) => i !== foundIndex);
      setSelectedCards(newArr);
    }
  };



  return (
    <>
        <h4>{convertStringToText(cardKey)}</h4>
        <div className='select-cards-grid'>
        { cards.flatMap((card, index) => (
              <Card
                className='select-card-container'
                key={`cards-${index}`}
                style={cardContainerStyles(index)}
                onClick={(event) => {
                  if (!event.target.closest('.icons-container')) {
                    handleSelectCard(card, index);
                  }
                }}
              >
              <CardContent>
              {card[0].type !== 'add-card' && (
                <span className='icons-container'>
                  <IconButton
                    onClick={(card) => handleSetIsReadOnly(index)}
                    aria-label="delete"
                    size="small"
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRemoveCard(index)}
                    aria-label="delete"
                    size="small"
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </span>
              )}
                {card.map((cardElement, cellIndex) => (
                  <div key={`card-${cellIndex}`} align="left">
                    {renderConfigLayout(
                      {
                        key: cardElement['content']['key'],
                        value: cardElement,
                        index: index,
                        setConfigJson,
                        setConfigLayout,
                        parentKey: cardKey,
                        parentType: 'select-cards',
                        isReadOnly: isReadOnly[index]
                      })}
                    {cardElement.type === 'add-card' && (
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button variant="contained" color="primary"
                          onClick={() => handleAddCard(cardElement['content'], index)}
                        >
                          Add Card
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
              </CardContent>
            </Card>
          ))
        }
        </div>
    </>
    
  );
}

export default SelectCards