// import * as React from 'react';
import Box from '@mui/material/Box';

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import "./Sidebar.scss"
import logo from '../../assets/logo.svg';
import { Link, useLocation } from "react-router-dom";
import routes from "../../routes";
import { Typography } from '@mui/material';
import { useState } from 'react';
// const sidebarLogoSrc = "https://media.licdn.cn/dms/image/C4D0BAQE7dmcXheFP1g/company-logo_200_200/0/1625451484857?e=2159024400&v=beta&t=vACP-3ox_Djcr_PF5aqDQlD8E65wM8gm6dn-Bz2pqvs";
// const sidebarLogoSrc = "https://tekvision.com/wp-content/uploads/tekvision-logo-red.svg";
const currentVersion = process.env.REACT_APP_VERSION;
const Sidebar = (props) => {
  const { onClose, drawerWidth, setViews } = props;
  const [active, setActive] = useState('configuration');
  
  const setRoute = (path)=> {
    setActive(path);
    setViews(path)
  }

  const Links = (props) => {
    let location = useLocation();
    
    const { routes, active } = props;

    const activeRoute = (routeName) => {
      return location.pathname.includes(routeName);
    };

   

    const createLinks = (routes) => {
      return (
        <List className='pl-24 mr'>
          {routes.map((route, index) => (
            // <Link key={index} to={route.layout + "/" + route.path} className='link-container'>
            <Link key={index} onClick={() => setRoute(route.path)} className={'link-container '+(route.path === active ? 'menu-active':'')}>
              <ListItem key={route.path} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText primary={route.name} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      );
    };    
    return <>{createLinks(routes)}</>;
  };

  const drawer = (
    <div>
      <Toolbar className='toolbar-container'>
        <img className='connex-logo' src={logo} alt="Connex Logo" />
      </Toolbar>
      <Links routes={routes} active={active} />
    </div>
  );  
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
    <Drawer
      variant="temporary"
      onClose={onClose}
      ModalProps={{keepMounted: true,}}
      sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
      }}
    >
      {drawer}
    </Drawer>
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
      }}
      open
    >
      {drawer}
      <Typography 
        variant='body2' 
        sx={{ position: 'absolute', bottom: '1rem', left: '1rem'}}
      > {currentVersion} </Typography>
    </Drawer>
  </Box>    
  );
};

export default Sidebar;
