import React, { useState, useRef, useEffect } from "react";
import { TextField, Box, Button, IconButton, Alert, Table, TableHead, TableRow, TableCell, TableBody, Pagination, FormControl, InputLabel, OutlinedInput, InputAdornment, Tooltip } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PreviewIcon from "@mui/icons-material/Preview";
import "./styles/v2.scss";
import TreeDiagramD3 from "../components/TreeDiagram/TreeDiagramD3";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  send_configuration_request,
  send_results_request,
} from "../backendCalls";
import httpService from "../api/http.service";
const Download = (props) => {
  const { downloadParams, 
          intentTree,
          selectedTreeNode,
          setDownloadParams,
          setSelectedTreeNode,
          setIntentTree,
          graphStatus,
          setGraphStatus,
          setViews
        } = props;

  const pageSize = 10;
  const [pageIndex, setPageIndex] = useState(0);

  const treeDiagramRef = useRef(null);
  const [analysisOutput, setAnalysisOutput] = useState("");
  const [treeShowNull, setTreeShowNull] = useState(false);
  const [testID, setTestID] = useState("");
  const [testInProgress, setTestInProgress] = useState(false);
  // const [previewDownload, setPreviewDownload] = useState({});
  const [previewDownload, setPreviewDownload] = useState([]);
  const [shouldAlert, setShouldAlert] = useState(false);
  const [utteranceBoolean, setUtteranceBoolean] = useState(false);

  const [filterValue, setFilterValue] = useState('');
  const [searchText, setSearchText] = useState('');


  const setFilter = (e)=> {
    setFilterValue(e.target.value);
  }

  const startSearch = ()=> {
    setSearchText(filterValue);
  }

  const [data, setData] = useState([]);
  const handleInputChange = (key, value) => {
    setShouldAlert(false);
    setDownloadParams((prev) => ({ ...prev, [key]: value }));
  };
  const handleDownloadClick = async () => {
    try {
      if(downloadParams.sessionId === "" && downloadParams.testId === ""){
        setShouldAlert(true);
        return;
      }
      await send_results_request(downloadParams, "zip");
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  const handlePreviewClick = async () => {
    try {
      if(downloadParams.sessionId === "" && downloadParams.testId === ""){
        setShouldAlert(true);
        return;
      }
      const response = await send_results_request(downloadParams, "previewZip");
      
      setPreviewDownload(response);
      setTestInProgress(true);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  useEffect(() => {
    const getResultsExpress = async () => {
      setIntentTree(null);
      // Testcase vPAzi6YWkp hb3n1MkQy8
      let resultsResponse = await send_results_request(downloadParams, "previewZip");
      // let resultsResponse = await send_results_request(testID, configJson["option"])
      resultsResponse?.[0]?.["resultObject"] && setIntentTree(resultsResponse[0]["resultObject"]);
        // formatAnalysisData(resultsResponse, newFormattedData, testID);
        resultsResponse[0]['crawlerStatusObject'] && setAnalysisOutput(resultsResponse[0]['crawlerStatusObject']);
        if(!resultsResponse[0]["testInProgress"]) {
          setTestInProgress(false)
        };
    };

    let intervalId = null;
    if (testInProgress) {
      intervalId = setInterval(getResultsExpress, 2000);
    }

    return () => {
      clearInterval(intervalId)
    };
  }, [testInProgress, downloadParams]);
  
  const loadHistory = async()=> {
    const data=await httpService.get(`/recent-crawler-batches?limit=50`);
    setData(data.data)
  }

  const resume = (id)=>async ()=> {
    localStorage.setItem('LOAD_BATCH',id);
    setViews('run')
  }

  useEffect(()=> {
    loadHistory();
  },[])
  
  const handleChange = (event, value) => {
    setPageIndex(value-1);
  };

  return (
    <div className="">
       <div className="page-title">
       <div className='config-page-header'>
            <span>Run History </span>
            <div>
              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Filter</InputLabel>
              <OutlinedInput
                onChange={setFilter}
                id="outlined-adornment-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={startSearch}
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                    <FilterAltOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Filter"
              />
            </FormControl>
            </div>
          </div>
        </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Last Updated </TableCell>
            <TableCell>Batch ID </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(data || []).filter(c=>!searchText || c.id.toLowerCase().indexOf(searchText.toLowerCase())!==-1).slice(pageIndex*pageSize,(pageIndex*pageSize)+pageSize).map((config, index) => (
           <TableRow key={index}>
            <TableCell>{config.lastUpdatedTime}</TableCell>
            <TableCell>{config.id}</TableCell>
            <TableCell className="flex">
              <Tooltip title="Use the 'Resume Batch' option on the mapping page to load a past run">
                <span>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={resume(config.id)}
                    disabled={true}
                  >
                    Load
                  </Button>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
        </TableBody>
      </Table>
      <div className='pagination-container'>
        <Pagination count={Math.ceil(data.filter(c=>!searchText || c.id.toLowerCase().indexOf(searchText.toLowerCase())!==-1).length/pageSize)} showFirstButton showLastButton page={pageIndex+1} onChange={handleChange}/>
      </div>
    </div>
  );
};

export default Download;
