import Configurations from "./pages/Configuration";
import AnalysisAndTranscript from "./pages/AnalysisAndTranscript";
import config from "./assets/config.svg";
import mapping from "./assets/mapping.svg";
import history from "./assets/history.svg";

const routes = [
  {
    name: "Configuration",
    layout: "/v2",
    path: "configuration",
    icon: <img src={config} alt=""/>,
    component: <Configurations />,
  },
  {
    name: "Mapping",
    layout: "/v2",
    path: "analysis-and-transcript",
    icon: <img src={mapping} alt=""/>,
    component: <AnalysisAndTranscript />,
  },
  {
    name: "Run History",
    layout: "/v2",
    path: "download",
    icon:  <img src={history} alt=""/>,
    component: <AnalysisAndTranscript />,
  },
];
export default routes;
