import React, { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { convertStringToText } from '../../utils/common';
// import { 
//   directed_dialogue_crawler_TEST_ACCOUNT_INTO,
//   natural_language_crawler_TEST_ACCOUNT_INTO,
//   DEFAULT_TEST_ACCOUNT_INTO,
// } from '../../customConfigJson';
import './styles/configTypes.scss'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Dropdown = (props) => {
  let {
    configKey, 
    configValue, 
    options, 
    isMultiple=false, 
    setConfigJson,
    setConfigLayout,
    index="",
    parentKey="",
    parentType="",
  } = props

  const [selectedOption, setSelectedOption] = useState(configValue);
  const parentTypes = [
    'input-group',
    'card',
    'select-cards',
    'table',
  ]
  useEffect(() => {
    if(!parentTypes.includes(parentType) && setConfigJson){
        setConfigJson((prevConfigJson) => ({ ...prevConfigJson, [configKey]: isMultiple ? configValue : configValue[0] }));
    }
  }, []);

  useEffect(() => {
    setSelectedOption(isMultiple ? configValue : configValue[0])
  }, [configValue]);

  const handleChange = (event) => {
    const { value } = event.target;
    let selectedOption = typeof value === 'string' ? value.split(',') : value;
    selectedOption = isMultiple ? selectedOption : selectedOption[0];

    setConfigJson((prevConfigJson) => {
      // Update default value for specific options (combined logic)
      // const updatedTestAccountInfo = configKey === 'option'
      //   ? (selectedOption === 'directed_dialogue_crawler' ? directed_dialogue_crawler_TEST_ACCOUNT_INFO_CONFIG_JSON : DEFAULT_TEST_ACCOUNT_INFO_CONFIG_JSON)
      //   : prevConfigJson.test_account_info;
      const  updatedTestAccountInfo = prevConfigJson.test_account_info;
    
      // Update selected option based on parent type
      const updatedConfigJson = {
        ...prevConfigJson,
        test_account_info: updatedTestAccountInfo,
      };
    
      switch (parentType) {
        case 'card':
        case 'table':
          updatedConfigJson[parentKey] = prevConfigJson[parentKey].map((cardData, cardIndex) =>
            cardIndex === index ? { ...cardData, [configKey]: selectedOption } : cardData
          );
          break;
        case 'select-cards':
          updatedConfigJson[parentKey][index] = {
            ...prevConfigJson[parentKey][index],
            [configKey]: selectedOption,
          };
          break;
        default:
          updatedConfigJson[configKey] = selectedOption;
      }
    
      return updatedConfigJson;
    });

    setConfigLayout((prevConfigJson) => {
      const updatedConfigJson = prevConfigJson.map((config) => {
        if (config.type === 'dropdown' && config.content.key === configKey) {
          return {
            ...config,
            content:{
              ...config.content,
              value:isMultiple ? selectedOption:[selectedOption],
            }
          }
        }
        return config;
      });
      return updatedConfigJson;
    });

    // const updateLayoutForOption = (prevJson, selectedOption) => {
    //   const configKey = 'option';
    //   return prevJson.map((item) => {
    //     if (configKey === 'option' && item.content.key === 'test_account_info') {
    //       let testAccountInfoContent = {};
    //       switch (selectedOption) {
    //         case 'directed_dialogue_crawler':
    //           testAccountInfoContent = directed_dialogue_crawler_TEST_ACCOUNT_INTO;
    //           break;
    //         case 'natural_language_crawler':
    //           testAccountInfoContent = natural_language_crawler_TEST_ACCOUNT_INTO;
    //           break;
    //         default:
    //           testAccountInfoContent = DEFAULT_TEST_ACCOUNT_INTO;
    //           break;
    //       }
    //       return {
    //         ...item,
    //         content: testAccountInfoContent,
    //       };
    //     }
    //     return item;
    //   });
    // };
    
    // const updateDropdownValue = (prevJson, configKey, selectedOption) => {
    //   return prevJson.map((item) => {
    //     if (item.type === 'dropdown' && item.content.key === configKey) {
    //       return {
    //         ...item,
    //         content: {
    //           ...item.content,
    //           value: [selectedOption],
    //         },
    //       };
    //     }
    //     return item;
    //   });
    // };
    
    // setConfigLayout((prevJson) => {
    //   // let updatedJson = updateLayoutForOption(prevJson, selectedOption);
    //   // updatedJson = updateDropdownValue(updatedJson, configKey, selectedOption);
    //   return prevJson;
    // });
    
      
    setSelectedOption(selectedOption);
    configValue = selectedOption;

  };

  const customWidth = () => {
    const halfWidthFields = ['application_type', 'option', 'connector', "conversation_gpt_version", "analysis_gpt_version"];
    return halfWidthFields.includes(configKey) ? 'calc(50% - 16px)' : '100%';
  };

  return (
    <FormControl sx={{ m: 1, width: customWidth(configKey) }}>

      <InputLabel id="demo-multiple-checkbox-label">{convertStringToText(configKey)}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        className='select-field'
        multiple={isMultiple}
        value={selectedOption}
        onChange={handleChange}
        input={<OutlinedInput label={convertStringToText(configKey)} />}
        renderValue={() => convertStringToText(selectedOption)}
        MenuProps={MenuProps}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {isMultiple && <Checkbox checked={selectedOption.indexOf(option) > -1} />}
            <ListItemText primary={convertStringToText(option)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Dropdown;