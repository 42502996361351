import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dropdown from './Dropdown';
import TextInput from './TextInput';
import Grid from '@mui/material/Grid';
import './styles/configTypes.scss'
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const Cards = (props) => {
  const { 
    cards,
    cardKey='',
    setConfigJson,
    setConfigLayout,
  } = props;

  useEffect(() => {
    const convertedConfigJson = { [cardKey]: [] };
    cards.forEach((card) => {
      const cardData = {};
      card.forEach((cardElement, index) => {
        const { key, value } = cardElement.content;
        if (cardElement.type === 'input') {
          cardData[key] = value;
        } else if (cardElement.type === 'dropdown') {
          cardData[key] = value;
        }
      });
  
      if (Object.keys(cardData).length > 0) {
        convertedConfigJson[cardKey].push(cardData);
      }
    });
  
    setConfigJson && setConfigJson((prevConfigJson) => ({
      ...prevConfigJson,
      [cardKey]: convertedConfigJson[cardKey],
    }));
  }, []);
  

  const handleAddCard = (fields, index) => {
    const convertedConfigJson = { [cardKey]: [] };
    const cardData = {};
    fields.forEach(field => {
      const { type } = field;
      const { key, value } = field['content'];
      if (type === 'input') {
        cardData[key] = value;
      } else if (type === 'dropdown') {
        cardData[key] = value;
      }
    })
    if (Object.keys(cardData).length > 0) {
      convertedConfigJson[cardKey].push(cardData);
    }

    setConfigLayout((prevConfigJson) => {
      const updatedConfigJson = prevConfigJson.map((config) => {
        if (config.type === 'cards' && config.content.key === cardKey) {
          const cardsArray = config.content.cards;
          const addFieldIndex = cardsArray.findIndex((card) => card[0].type === 'add-card');
          if (addFieldIndex >= 0) {
            cardsArray.splice(addFieldIndex, 0, fields);
          }
        }
        return config;
      });
      return updatedConfigJson;
    });
    
    setConfigJson((prevConfigJson) => ({
      ...prevConfigJson,
      [cardKey]: [...prevConfigJson[cardKey], ...convertedConfigJson[cardKey]],
    }));
  }

  const handleRemoveCard = (index) => {
    setConfigLayout((prevConfigJson) => {
      const updatedConfigJson = prevConfigJson.map((config) => {
        if (config.type === 'cards' && config.content.key === cardKey) {
          config.content.cards = config.content.cards.filter((_, currentIndex) => currentIndex !== index);
        }
        return config;
      });
      return updatedConfigJson;
    });

    setConfigJson((prevConfigJson) => ({
      ...prevConfigJson,
      [cardKey]: prevConfigJson[cardKey].filter((_, currentIndex) => currentIndex !== index),
    }));
  }

  return (
    <>
      <h4 style={{ textTransform: 'capitalize' }}>{cardKey.replaceAll('_', ' ')}</h4>
      <Grid container spacing={2}>
        {cards.flatMap((card, index) => (
          <Grid key={`cards-${index}`} sx={{ m: 1 }}>
            <Card key={`card-${index}`} className='card-container' style={{ position: 'relative' }}>
              {card.map((cardElement, cardElementIndex) => {
                return (
                  <CardContent key={`${cardElement['content']['key'] || cardElement['content']['value']}-${index}`}>
                    {cardElement.type === 'input' && (
                      <TextInput
                        configKey={cardElement['content']['key'] || cardElementIndex}
                        configValue={cardElement['content']['value']}
                        setConfigJson={setConfigJson}
                        setConfigLayout={setConfigLayout}
                        index={index}
                        parentKey={cardKey}
                        parentType='card'
                      />
                    )}
                    {cardElement.type === 'dropdown' && (
                      <Dropdown
                        configKey={cardElement['content']['key']}
                        options={cardElement['content']['options']}
                        configValue={cardElement['content']['value']}
                        isMultiple={cardElement['content']['isMultiple']}
                        setConfigJson={setConfigJson}
                        setConfigLayout={setConfigLayout}
                        index={index}
                        parentKey={cardKey}
                        parentType='card'
                      />
                    )}
                    {cardElement.type === 'add-card' && (
                      <Button variant="contained" color="primary"
                        onClick={() => handleAddCard(cardElement['content'], index)}
                      >
                        Add Card
                      </Button>
                    )}
                  </CardContent>
                );
              })}
              {card.every((cardElement) => cardElement.type !== 'add-card') && (
                <IconButton
                  onClick={() => handleRemoveCard(index)}
                  aria-label="delete"
                  size="small"
                  style={{ position: 'absolute', top: '0.25rem', right: '0.25rem' }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default Cards;