import React, { useState, useEffect } from 'react';
import defaultJson from '../config.json'; // Update the path

function ConfigColumn() {
  const [jsonValue, setJsonValue] = useState('');

  useEffect(() => {
    // Set the default JSON value when the component mounts
    setJsonValue(JSON.stringify(defaultJson, null, 2));
  }, []);

  const handleInputChange = (event) => {
    setJsonValue(event.target.value);
  };

  return (
    <div id="configColumn" className="configColumn flexColumn">
      <div className="titleRow">
        <h2>Configuration</h2>
      </div>
      <textarea
        id="jsonInput"
        className="configBox boxsizingBorder textAreaSize"
        value={jsonValue}
        onChange={handleInputChange}
      ></textarea>
    </div>
  );
}

export default ConfigColumn;
