import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import './styles/v2.scss'

const RawJsonView = (props) => {
  const {
    configJson,
    setConfigJson,
  } = props;

  useEffect(() => {
    const jsonString = configJson;
    if(typeof jsonString === 'object'){
      setConfigJson(JSON.stringify(jsonString, null, 2))
      // setConfigJson(jsonString)
    }
  }, []);

  const handleInputChange = (event) => {
    setConfigJson(event.target.value)
  };

  return (
    <TextField
      style={{ backgroundColor: 'white', width: '100%'}}
      id="standard-multiline-flexible"
      label="Raw JSON"
      multiline
      value={typeof configJson === 'string' ? configJson : ''}
      onChange={handleInputChange}
      InputProps={{ style: { fontFamily: 'monospace' } }}
    />
  );
};

export default RawJsonView;