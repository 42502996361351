import axios from "axios";

// const getToken = () => {
//     if (localStorage.getItem('TOKEN')) {

//         return {
//             headers: {
//                 'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
//             }
//         }
//     }

//     return {};
// }


export default axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    timeout: 30000,
    //...getToken(),
});
