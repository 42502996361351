import React, { useState, useEffect } from 'react';
import { convertStringToText } from '../../utils/common';
import './styles/configTypes.scss'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


const Tags = (props) => {
  let {
    configKey, 
    configValue, 
    options, 
    isMultiple=true,
    configJson={},
    setConfigJson,
    setConfigLayout,
    index="",
    parentKey="",
    parentType="",
  } = props

  const [selectedOption, setSelectedOption] = useState(configValue);
  const [updateOptions, setUpdateOptions] = useState(options);
  const parentTypes = [
    'input-group',
    'card',
    'select-cards',
    'table',
  ]
  const halfWidthFields = ["verify_session_id", "initial_user_messages"];
  useEffect(() => {
    if(!parentTypes.includes(parentType) && setConfigJson){
        setConfigJson((prevConfigJson) => ({ ...prevConfigJson, [configKey]: isMultiple ? configValue : configValue[0] }));
    }
    if(parentKey === 'test_account_info'){
        configJson.task_list &&  setUpdateOptions(['Global', ...configJson.task_list])
    }
  }, []);

  useEffect(() => {
    setSelectedOption(isMultiple ? configValue : configValue[0])
  }, [configValue]);

  const handleChange = (value) => {
    let selectedOption = typeof value === 'string' ? value.split(',') : value;
    selectedOption = isMultiple ? selectedOption : selectedOption[0];
  
    setConfigJson((prevConfigJson) => {
      switch(parentType){
        case 'card':
        case 'table':
          return {
            ...prevConfigJson,
            [parentKey]: prevConfigJson[parentKey].map((cardData, cardIndex) => {
              return cardIndex === index ? { ...cardData, [configKey]: selectedOption } : cardData;
            }),
          };
        case 'select-cards':
          const updatedCard = {
            ...prevConfigJson[parentKey][index],
            [configKey]: selectedOption,
          };
          const updatedConfigJsonSelectableCard = { ...prevConfigJson };
          updatedConfigJsonSelectableCard[parentKey][index] = updatedCard;
          return updatedConfigJsonSelectableCard;
        case 'input-group':
          const updatedConfigJsonList = { ...prevConfigJson };
          updatedConfigJsonList[parentKey][index] = selectedOption;
          return updatedConfigJsonList;
        default:
          return {
            ...prevConfigJson,
            [configKey]: selectedOption || value,
          };          
      }
    });
 
    setConfigLayout((prevJson) => {
      const updatedJson = prevJson.map((item) => {
        if (item.type === parentType && item.content.key === parentKey) {
          const updatedRows = item.content.rows.map((row, rowIndex) => {
            if (rowIndex === index) {
              return row.map((cell) => {
                if (cell.type === 'tags' && cell.content.key === configKey) {
                  return {
                    ...cell,
                    content: {
                      ...cell.content,
                      value: selectedOption,
                    },
                  };
                }
                return cell;
              });
            }
            return row;
          });
    
          return {
            ...item,
            content: {
              ...item.content,
              rows: updatedRows,
            },
          };
        }

        if (item.type === 'tags' && item.content.key === configKey) {
          return {
            ...item,
            content: {
              ...item.content,
              value: selectedOption,
            },
          };
        }               
        return item;
      });
    
      return updatedJson;
    });

    setSelectedOption(selectedOption);
    configValue = selectedOption;
  };

  const customStyles = () => {
    return {
      width: halfWidthFields.includes(configKey) ? 'calc(50% - 16px)' : '99%',
      margin: halfWidthFields.includes(configKey) ? '8px' : '8px 8px'
    };
  };

  return (
    <>
      <Autocomplete
        className='autocomplete-container'
        multiple
        freeSolo
        sx={customStyles()}
        id={`tags-${configKey}`}
        options={updateOptions}
        getOptionLabel={(option) => convertStringToText(option)}
        value={isMultiple ? selectedOption : [selectedOption]}
        onChange={(_event, newValue) => { handleChange(newValue) }}
        renderInput={(params) => (  
          <TextField
            {...params}
            label={convertStringToText(configKey)}
            placeholder="Add more..."
          />
        )}
      />    
    </>
  );
}

export default Tags;