import React, { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { convertStringToText } from '../../utils/common';
import './styles/configTypes.scss'
const TextInput = (props) => {
  let {
    configKey, // custom_instructions
    configValue, // Do not say tellerphone
    index="", //0 , 1, 2
    parentKey="", //custom_instructions
    parentType="",
    setConfigJson,
    setConfigLayout,
    isReadOnly=false,
  } = props
  const [inputValue, setInputValue] = useState(configValue);
  const parentTypes = [
    'input-group',
    'card',
    'select-cards',
    'table',
  ]

  const smallFields = ['task_name', 'goal', 'expected_result', 'num_variations']
  const halfWidthFields = ['bot_connector_url', "verify_test_id", "phone_number", "batch_id", "other"];

  useEffect(() => {
    if(!parentTypes.includes(parentType) && setConfigJson){
      setConfigJson((prevConfigJson) => ({ ...prevConfigJson, [configKey]: configValue }));
    }
  }, []);

  useEffect(() => {
    setInputValue(configValue)
  }, [configValue]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
  
    if (!setConfigJson) {
      return;
    }
    setConfigJson((prevConfigJson) => {
      switch(parentType){
        case 'card':
        case 'table':
        case 'select-cards':
          const updateConfig = {
            ...prevConfigJson,
            [parentKey]: prevConfigJson[parentKey].map((cardData, cardIndex) => {
              return cardIndex === index ? { ...cardData, [configKey]: newValue } : cardData;
            }),
          }
          return updateConfig;
        case 'input-group':
          const updatedConfigJsonList = { ...prevConfigJson };
          updatedConfigJsonList[parentKey][index] = newValue;
          return updatedConfigJsonList;
        default:
          return {
            ...prevConfigJson,
            [configKey]: newValue,
          };                     
      }
    });

    setConfigLayout((prevJson) => {
      const updatedJson = prevJson.map((item) => {
        if (item.type === 'table' && item.content.key === parentKey) {
          const updatedRows = item.content.rows.map((row, rowIndex) => {
            if (rowIndex === index) {
              return row.map((cell) => {
                if (cell.type === 'input' && cell.content.key === configKey) {
                  return {
                    ...cell,
                    content: {
                      ...cell.content,
                      value: newValue,
                    },
                  };
                }
                return cell;
              });
            }
            return row;
          });
    
          return {
            ...item,
            content: {
              ...item.content,
              rows: updatedRows,
            },
          };
        }
        if (item.type === 'select-cards' && item.content.key === parentKey) {
          const updatedCards = item.content.cards.map((card, cardIndex) => {
            if (cardIndex === index) {
              return card.map((cell) => {
                if (cell.type === 'input' && cell.content.key === configKey) {
                  return {
                    ...cell,
                    content: {
                      ...cell.content,
                      value: newValue,
                    },
                  };
                }
                return cell;
              });
            }
            return card;
          });
    
          return {
            ...item,
            content: {
              ...item.content,
              cards: updatedCards,
            },
          };
        }
        if (item.type === 'input-group' && item.content.key === parentKey) {
          const updatedFields = item.content.fields.map((field, fieldIndex) => {
            if (fieldIndex === index && field.type === 'input') {
              return {
                ...field,
                content: {
                  ...field.content,
                  value: newValue,
                },
              };
            }
            return field;
          });
    
          return {
            ...item,
            content: {
              ...item.content,
              fields: updatedFields,
            },
          };
        }
        if (item.type === 'input' && item.content.key === configKey) {
          return {
            ...item,
            content: {
              ...item.content,
              value: newValue,
            },
          };
        }        
        return item;
      });
    
      return updatedJson;
    });
  };
  
  const customStyles = () => {
    if(isReadOnly){
      return {
        background: 'transparent',
        width: '100%',
        margin: '8px 0',
        border: 'none',"& fieldset": { border: 'none' },
      }
    }
    return {
      width: halfWidthFields.includes(configKey) ? 'calc(50% - 16px)' : '100%',
      margin: halfWidthFields.includes(configKey) ? '8px' : '8px 0'
    };
  };
  
  const customTextSize = () => {
    return smallFields.includes(configKey) ? 'small' : ''
  }
  return (
      <TextField
        className='TextField'
        sx={customStyles()}
        size={smallFields.includes(configKey) ? 'small' : 'medium'}
        variant="outlined"
        id={`id-configKey`}
        // multiline
        label={typeof configKey === 'string' ? convertStringToText(configKey) : ''}
        value={inputValue}
        onChange={handleChange}      
        InputProps={{
          readOnly: isReadOnly
        }}
      />  
  );
};

export default TextInput;