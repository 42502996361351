import React, { useState, useEffect } from 'react';
import Home from "./pages/Home"
import Default from './pages/default';
import LoginPage from './pages/LoginPage';
import { Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import ConfigColumn from './components/ConfigColumn';
import ResultsColumn from './components/ResultsColumn';
import Footer from './components/Footer';
import './style.scss'
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = (loginStatus) => {
    setIsLoggedIn(loginStatus);
  };

  return (
    <>
      {/* <Home/> */}
      <Routes>
        {/* <Route path="configuration" element={<Configurations {...commonConfigProps} />} />
        <Route path="analysis-and-transcript" element={<AnalysisAndTranscript {...commonConfigProps} />} /> */}
        <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
        <Route path="/v1/*" element={isLoggedIn ? <Default /> : <Navigate to="/login" />} />
        <Route path="/*" element={isLoggedIn ? <Home /> : <Navigate to="/login" />} />        
      </Routes>      
    </>
    // <div className="container">
    //   <Header />
    //   <div className="body">
    //     <div className="configContainer">
    //       <ConfigColumn />
    //     </div>
    //     <ResultsColumn />
    //   </div>
    //   <Footer />
    // </div>
  );
}

export default App;