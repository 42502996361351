import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { Tooltip } from "@mui/material";
import styled from 'styled-components';

const CardNode = styled.div`
  padding: 8px;
  border-radius: 8px;
  display: inline-block;
  background-color: #f4f7fd;
`;

const TreeDiagram = (props) => {
  const { intentTree,
          showNull,
          selectedTreeNode,
          setSelectedTreeNode,
          setConvertedTranscript,
          setOriginalTranscript} = props;
  const [selectedNode, setSelectedNode] = React.useState("");


  const formatTreeNodesContent = (content, conversationId = "") => {
    const parts = content.split("__");
    if (parts[1] === "null") {
      parts.length = 1;
    }
    return parts.join(" ").split("_").join(" ") + "\n" + conversationId;
  };


  const generateNodeId = (node) => {
  if (node?.stepInfo?.userMessages) {
    return node.stepInfo.userMessages.join("-");
  }
  return "";
};

const handleSelectNode = (node) => {
  const nodeId = generateNodeId(node);
  setSelectedTreeNode(selectedNode === nodeId ? '' : node);
  setSelectedNode(selectedNode === nodeId ? '' : nodeId);
  const defaultTranscript = [{ content: "No content", role: "NLU System" }];
  const resultsResponse = JSON.parse(sessionStorage.getItem('resultsResponse'));
  const transcriptList = resultsResponse?.sessionResultsArray ?? [];
  const sessionId = node.stepInfo?.session_id ?? undefined;
  console.log("session id: ", sessionId);
  console.log(resultsResponse);
  if (sessionId) {
    const transcript = transcriptList.find(item => item.id === sessionId);
    setConvertedTranscript((transcript.convertedTranscript && transcript.convertedTranscript.length > 0) ? transcript.convertedTranscript : defaultTranscript);
    setOriginalTranscript((transcript.transcript && transcript.transcript.length > 0) ? transcript.transcript : defaultTranscript);
  } else {
    setConvertedTranscript(defaultTranscript);
    setOriginalTranscript(defaultTranscript);
  }
};

const renderTreeNodes = (tree, isRoot = false) => {
  let renderTree = { ...tree };
  if (!renderTree) return <CardNode>Call Start</CardNode>;
  const labelContent = renderTree?.stepInfo?.userMessages?.map((message, index) => (<li key={index}>{message}</li>));

  const nodeId = generateNodeId(renderTree);

  if (isRoot) {
    return (
      <TreeNode key={nodeId} label={
        <Tooltip title={<div>{labelContent}</div>}>
          <CardNode
            onClick={() => { handleSelectNode(renderTree); }}
            style={{ border: selectedNode === nodeId ? "2px solid #7cb8f3" : "none", }}
          > Call Start</CardNode>
        </Tooltip>
      }>
      </TreeNode>
    );
  } else {
    return renderTree['submenu']?.map((node) => {
      const childNodeId = generateNodeId(node);
      return (
        <TreeNode key={childNodeId} label={
          <Tooltip title={<div>{labelContent}</div>}>
            <CardNode
              onClick={() => { handleSelectNode(node); }}
              style={{ border: selectedNode === childNodeId ? '2px solid #7cb8f3' : 'none' }}
            >{formatTreeNodesContent(node.name)}</CardNode>
          </Tooltip>
        }>
          {node && renderTreeNodes(node)}
        </TreeNode>
      );
    });
  }
};

return (
  <Tree key="tree-root" label={renderTreeNodes(intentTree, true)}>
    {intentTree && renderTreeNodes(intentTree)}
  </Tree>
);
};

export default TreeDiagram;