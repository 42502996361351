import * as React from 'react';
import { 
  Button,
  Card,
  CardContent
} from "@mui/material";
import { MessageLeft, MessageRight } from "./Message";

const Transcript = (props) => {
  const {
    transcriptOutput,
    handleDownload,
  } = props;
  
  return (
    <div className="transcript-container">
      <div className="titleRow">
        <h2>Transcript</h2>
        <Button className="big" onClick={handleDownload}> Download Session </Button>
      </div>
      <Card sx={{borderRadius: '8px', minHeight: '400px', height: '50vh', overflow: 'auto'}}>
        <CardContent>
        { transcriptOutput.map((dialog, index) => (
            <span key={index}>
              {dialog.split(`\n\t`)[0] === 'VIRTUAL ASSISTANT:' && (
                <MessageLeft
                  message={dialog.split(`\n\t`)[1]}
                  displayName="VIRTUAL ASSISTANT"
                />
              )}
              {dialog.split(`\n\t`)[0] === 'LLM:' && (
                <MessageRight
                  message={dialog.split(`\n\t`)[1]}
                  displayName="LLM"
                />
              )}
              <br />
            </span>
          ))
        }              
        </CardContent>
      </Card>
    </div>
  );
}

export default Transcript;