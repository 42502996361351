import React, { useState, useEffect } from 'react';

import {  formatTranscriptDatav1, formatAnalysisDatav1, getCurrentTimestamp  } from "../utils/utilityFunctions"
import {  send_configuration_request, send_results_request  } from "../backendCalls"

function ResultsColumn() {
  const [analysisOutput, setAnalysisOutput] = useState("");
  const [transcriptOutput, setTranscriptOutput] = useState("");
  //const [isDownloadDisabled, setIsDownloadDisabled] = useState(true);
  const [testID, setTestID] = useState("");
  const [testInProgress, setTestInProgress] = useState(false);
  const [formattedData, setFormattedData] = useState({});
  const [selectedSession, setSelectedSession] = useState("status")


  useEffect(() => {
    let defaultAnalysis = (testID === "") ? "" : `TEST ID: ${testID}\nTEST IN PROGRESS: ${testInProgress}`;

    setTranscriptOutput(formattedData?.[selectedSession]?.["transcript"] || '')
    setAnalysisOutput(formattedData?.[selectedSession]?.["analysis"] || defaultAnalysis)
  }, [selectedSession, formattedData, testID, testInProgress]);


  useEffect(() => {
    const getResultsExpress = async () => {
      let resultsResponse = await send_results_request(testID)
      let newFormattedData = {}
  
      formatTranscriptDatav1(resultsResponse, newFormattedData)
      formatAnalysisDatav1(resultsResponse, newFormattedData, testID)
  
      setFormattedData(newFormattedData)
      if (!resultsResponse["testInProgress"]) setTestInProgress(false);
    };


    let intervalId = null
    if (testInProgress){
      intervalId = setInterval(getResultsExpress, 2000);
    } 
    
    return () => clearInterval(intervalId);
  }, [analysisOutput, transcriptOutput, testInProgress, formattedData, testID]);


  const handleRunTesterExpress = async () => {
    const config = JSON.parse(document.getElementById('jsonInput').value);
    const botConnectorUrl = config["bot_connector_url"]
    setTestInProgress(true)
    setFormattedData({})
    setSelectedSession("status")
    
    setTestID("")

    let configResponse = await send_configuration_request(config, botConnectorUrl)

    setTestID(configResponse["testID"])
  };

  const handleDisplaySwitch = (key) => {
    setSelectedSession(key);
  };


  const handleDownload = () => {
    const element = document.createElement("a"); // Create a new anchor element

    let file_text = `${analysisOutput}\n\n-------------------------------------------------------------------------------------------------------------------------\n\n` + transcriptOutput
    let timestamp = getCurrentTimestamp()
    const file = new Blob([file_text], { type: 'text/plain' }); // Create a Blob object from the text
  
    element.href = URL.createObjectURL(file); // Set the 'href' attribute of the anchor element to the Blob URL
    element.download = `session_transcript_${timestamp}.txt`; // Set the 'download' attribute to the desired file name
  
    document.body.appendChild(element); // Append the anchor element to the DOM
  
    element.click(); // Programmatically trigger a click event on the anchor element
  
    document.body.removeChild(element); // Remove the anchor element from the DOM after the click event
  };


  return (
    <div id="resultsColumn" className="resultsColumn flexColumn">
      <div id="analysisBlock" className="flexColumn">
        <div className="titleRow">
          <h2>Analysis</h2>
          <button className='big' onClick={handleRunTesterExpress}>Run Test</button>
          <button key={"status"} onClick={() => handleDisplaySwitch("status")} className={"status" === selectedSession ? 'active' : ''}>Test Summary</button>
          <div className="session-switch-container"></div>
            {Object.keys(formattedData).map((key) => (
              key !== "status" ? (
                <button
                  key={key}
                  onClick={() => handleDisplaySwitch(key)}
                  className={key === selectedSession ? 'active' : ''}
                >
                  {key}
                </button>
              ) : null
            ))}
        </div>
        <textarea
          className="analysisBox boxsizingBorder textAreaSize"
          id="analysisOutput"
          value={analysisOutput}
          readOnly
        ></textarea>
      </div>
      <div id="transcriptBlock" className="flexColumn maxHeight">
        <div className="titleRow">
          <h2>Transcript</h2>
          <button className='big' onClick={handleDownload}>Download Session</button>
        </div>
        <textarea 
          id="jsonOutput" 
          className="transcriptBox boxsizingBorder textAreaSize"
          value={transcriptOutput}
          readOnly
        ></textarea>
        <div></div>
      </div>
    </div>
  );
}

export default ResultsColumn;
