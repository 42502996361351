import React from "react";

export const MessageLeft = (props) => {
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const displayName = props.displayName ? props.displayName : "";

  return (
    <div className="messageRow">
      <div>
        <div className="displayName">{displayName}</div>
        <div className="messageLeft">
          <div>
            <p className="messageContent">{message}</p>
          </div>
          <div className="messageTimeStampRight">{timestamp}</div>
        </div>
      </div>
    </div>
  );
};

export const MessageRight = (props) => {
  const message = props.message ? props.message : "no message";
  const displayName = props.displayName ? props.displayName : "";
  const timestamp = props.timestamp ? props.timestamp : "";

  return (
    <div className="messageRowRight">
      <div className="messageRowRightFlex">
        <div className="displayNameRight">{displayName}</div>
        <div className="messageRight">
          <div>
            <p className="messageContent">{message}</p>
          </div>
          <div className="messageTimeStampRight">{timestamp}</div>
        </div>
      </div>
    </div>
  );
};