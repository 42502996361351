import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dropdown from './Dropdown';
import TextInput from './TextInput';
import Grid from '@mui/material/Grid';
import './styles/configTypes.scss'
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const InputGroup = (props) => {
  const { 
    configKey,
    fields,
    setConfigJson,
    setConfigLayout,
  } = props;
  
  useEffect(() => {
    const convertedConfigJson = [];
    fields.forEach((field) => {
      const { value } = field.content;
      if (field.type === 'input') {
        convertedConfigJson.push(value)
      }
    });
  
    setConfigJson && setConfigJson((prevConfigJson) => ({
      ...prevConfigJson,
      [configKey]: convertedConfigJson,
    }));
  }, []);

  const handleAddField = (fieldContent) => {  
    setConfigLayout((prevConfigJson) => {
      const updatedConfigJson = prevConfigJson.map((config) => {
        if (config.type === 'input-group' && config.content.key === configKey) {
          const fieldsArray = config.content.fields;
          const addFieldIndex = fields.findIndex((field) => field.type === 'add-field');
  
          if (addFieldIndex >= 0) {
            fieldsArray.splice(addFieldIndex, 0, fieldContent);
          } else {
            fieldsArray.push(fieldContent);
          }
        }
        return config;
      });
      return updatedConfigJson;
    });

    setConfigJson((prevConfigJson) => {
      const addFieldIndex = fields.findIndex((field) => field.type === 'add-field');
      const updatedConfigKey = [
        ...prevConfigJson[configKey].slice(0, addFieldIndex),
        fieldContent['value'] || "",
        ...prevConfigJson[configKey].slice(addFieldIndex),
      ];
      return {
        ...prevConfigJson,
        [configKey]: updatedConfigKey,
      };
    });    
  };

  const handleRemoveCard = (index) => {
    setConfigLayout((prevConfigJson) => {
      const updatedConfigJson = prevConfigJson.map((config) => {
        if (config.type === 'input-group' && config.content.key === configKey) {
          config.content.fields = config.content.fields.filter((_, currentIndex) => currentIndex !== index);
        }
        return config;
      });
      return updatedConfigJson;
    });

    setConfigJson((prevConfigJson) => ({
      ...prevConfigJson,
      [configKey]: prevConfigJson[configKey].filter((_, currentIndex) => currentIndex !== index),
    }));
  }  
    
  return (
    <>
      <h4 style={{ textTransform: 'capitalize' }}>{configKey.replaceAll('_', ' ')}</h4>
          <Grid sx={{ m: 1}}>
            <Card className='card-container'>
              <CardContent>
                {fields.map((field, fieldIndex) => {
                  if (field.type === 'input') {
                    return (
                      <div className="input-group-row-container" key={`input-${fieldIndex}`}>
                        <TextInput
                          key={`${fieldIndex}`}
                          configKey={field['content']['key']}
                          configValue={field['content']['value']}
                          parentKey={configKey}
                          setConfigJson={setConfigJson}
                          setConfigLayout={setConfigLayout}
                          index={fieldIndex}
                          parentType='input-group'
                        ></TextInput>
                        <IconButton sx={{width:36, height:36, ml: 2}} onClick={() => handleRemoveCard(fieldIndex)} aria-label="delete" size="small">
                          <CloseIcon fontSize="inherit" />
                        </IconButton>   
                      </div>
                     );
                  }
                  if (field.type === 'dropdown') {
                    return (
                        <Dropdown
                        key={`${fieldIndex}`}
                        configKey={field['content']}
                        options={field['content']['options']}
                        configValue={field['content']['value']}
                        isMultiple={field['content']['isMultiple']}
                        setConfigJson={setConfigJson}    
                        index={fieldIndex}
                        parentType='input-group' 
                        parentKey={configKey}                                       
                      />                      
                    );
                  }
                  if (field.type === 'add-field') {
                    return (
                      <Button variant="contained" color="primary"
                        key={`${field['content']['key']}-${fieldIndex}`}
                        onClick={() => handleAddField(field['content'], fieldIndex)}
                      >Add Field</Button>
                    )
                  }
                  return null;
                })}
              </CardContent>
              {/* <Button onClick={handleRemoveCard}>DELETE</Button> */}
            </Card>
          </Grid>
    </>
  );
}

export default InputGroup;