import React from 'react';
import './TranscriptBox.css';
import '../../pages/styles/default.scss'; // Import the SCSS file
import { createRoot } from 'react-dom/client';
import * as d3 from "d3";

import { IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const showTranscript = (d, x, y, setUtteranceBoolean) => {
    const defaultTranscript = [{content: "No content", role: "NLU System"}];
    const resultsResponse = JSON.parse(sessionStorage.getItem('resultsResponse'));
    const transcriptList = resultsResponse?.sessionResultsArray ?? [];
    const sessionId = d.data.stepInfo?.session_id ?? undefined;

    let convertedTranscript = defaultTranscript;
    let originalTranscript = defaultTranscript;
    console.log(sessionId);

    if(sessionId){
        const transcript = transcriptList.find(item => item.id === sessionId);
        convertedTranscript = (transcript.convertedTranscript && transcript.convertedTranscript.length > 0) ? transcript.convertedTranscript : defaultTranscript;
        originalTranscript = (transcript.transcript && transcript.transcript.length > 0) ? transcript.transcript : defaultTranscript;
    }
    d3.select('#graphSvg').selectAll('.transcript').remove();

    const menu = d3.select('#graphSvg').append('g')
      .attr('class', 'transcript')
      .append("foreignObject")
      .attr("x", x)
      .attr("y", y)
      .attr('rx', 2)
      .attr("width", 800)
      .attr("height", 1000);
  
    let offsetX, offsetY;
  
    const drag = d3.drag()
      .on("start", function(event) {
        offsetX = event.x - parseFloat(d3.select(this).attr("x"));
        offsetY = event.y - parseFloat(d3.select(this).attr("y"));
      })
      .on("drag", function(event) {
        d3.select(this)
          .attr("x", event.x - offsetX)
          .attr("y", event.y - offsetY);
      });
    
    menu.call(drag);
  
    const body = document.createElement("div");
    menu.node().appendChild(body);
  
    const handleClose = () => {
      root.unmount();
      body.remove();
      d3.select('#graphSvg').selectAll('.transcript').remove();
    };
    const root = createRoot(body);
    root.render(<TranscriptBox onClose={handleClose} originalTranscript={originalTranscript} convertedTranscript={convertedTranscript} />);
};

export function TranscriptBox({ showClose=true, onClose, originalTranscript, convertedTranscript }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', margin: 'auto'}}>
        {/* Close Icon and button */}
        <div style={{ 
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1001
        }}>
            {showClose && <IconButton onClick={onClose} color="primary">
                <CloseIcon fontSize="inherit" />
            </IconButton>}
        </div>

        {/* Container for both chats */}
        <div className="transcript-box-container">
            {/* Left Chat Container */}
            { originalTranscript[0].content !== "No content" && <div className="chat-container" style={{fontSize: '13px'}}>
                <Typography variant="h6" className="sticky-header">Directed Dialogue</Typography>
                {originalTranscript.map((message, index) => (
                    <div key={index} className={`message ${message.role === 'User' ? 'user' : 'system'}`}>
                        {message.content}
                    </div>
                ))}
            </div>}
            {/* Right Chat Container */}
            { convertedTranscript[0].content !== "No content" && <div className="chat-container" style={{fontSize: '13px'}}>
                <Typography variant="h6" className="sticky-header">NLU Sample</Typography>
                {convertedTranscript.map((message, index) => (
                    <div key={index} className={`message ${message.role === 'User' ? 'user' : 'system'}`}>
                        {message.content}
                    </div>
                ))}
            </div>}
        </div>
    </div>
  );
};

export default showTranscript;